import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';


class GlobalAlert extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.element = document.createElement('div');
//   }

//   componentDidMount() {
//     globalAlertRoot.appendChild(this.element);
//   }

//   componentWillUnmount() {
//     globalAlertRoot.removeChild(this.element);
//   }

  render() {
    const { success, children, isOpen, toggle } = this.props;

    return ReactDOM.createPortal(
      <Alert
        color={success || 'danger'}
        className="fixed-alert"
        isOpen={isOpen}
        toggle={toggle}
      >
        {children}
      </Alert>,
     document.getElementById('global-alert'),
    );
  }
}

GlobalAlert.propTypes = {
  success: PropTypes.string,
  children: PropTypes.any,
};

GlobalAlert.defaultProps = {
  success: false,
  children: 'Error inesperado en el servidor, inténtelo de nuevo',
};

export default GlobalAlert;
