import React, { useState, useEffect } from 'react';
import SkeletonLoader from 'components/Common/SkeletonLoader';
import Typography from 'components/Typography';
import Select from 'react-select';
import { Alert, Col, Collapse, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { SupermarketService } from 'services';
import { DateUtils } from 'utils';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdSearch } from 'react-icons/md';
import SalesTicketTable from './SalesTicketTable';


const SalesTicketContainer = ({ context, warehouses }) => {
    const [alert, setAlert] = useState({ type: '', message: '' });
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({ preparation_date: DateUtils.formatDateForHtmlInput(), warehouse: null });
    const [tickets, setTickets] = useState([]);
    const [collapsedOrders, setCollapsedOrders] = useState([]);

    useEffect(() => {
        fetchSalesTickets();
    }, [form]);

    const fetchSalesTickets = async (preparation_date, id_client) => {
        setTickets([]);
        setAlert({ type: '', message: '' })
        setLoading(true);

        const supermarketService = new SupermarketService();

        const response = await supermarketService.salesTicketOnDate(
            preparation_date || form.preparation_date,
            id_client || context['session']['payload']['id_client']
        );

        if (response.success) {
            setTickets(response.tickets || []);
            setAlert({ type: '', message: '' })

        } else {
            setTickets([]);
            setAlert({ type: 'danger', message: response.message })
        }

        setLoading(false);
    }

    const handleInputChange = (event) => setForm({
        ...form,
        [event.target.name]: event.target.value

    })


    const handleWarehouseChange = warehouse => setForm({
        ...form,
        warehouse
    })

    const handleCollapse = (event) => {
        const id_order = event.target.dataset.order;

        if (collapsedOrders.includes(id_order)) {
            setCollapsedOrders(collapsedOrders.filter(collapsed_id_order => collapsed_id_order !== id_order));
        } else {
            setCollapsedOrders(collapsedOrders.concat([id_order]));
        }
    }

    return (
        <div>
            <Form className="w-100">
                <Row>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <Label for="preparation_date">Fecha de preparación</Label>
                            <Input
                                onChange={handleInputChange}
                                value={form.preparation_date}
                                className="max-width-input"
                                type="date"
                                id="preparation_date"
                                name="preparation_date" />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <Label for="warehouses">Mostrar los de almacen:</Label>
                            <Select
                                isClearable
                                placeholder="Todos"
                                className="w-100 max-width-250"
                                id="warehouses"
                                name="warehouse-selector"
                                options={warehouses && warehouses.map(warehouse => ({ label: warehouse.name, value: warehouse.id }))}
                                onChange={handleWarehouseChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            {alert.type && (<Alert className="my-2 mx-1" color={alert.type}>{alert.message}</Alert>)}

            {!loading && !tickets.length && (
                <Typography type="lead" className="text-center my-3 mx-1">
                    <MdSearch size={30} className="mr-1" />
                    <span>No se han encontrado tickets para la fecha {form.preparation_date}</span>
                </Typography>)}

            {loading ? <SkeletonLoader type="complex" />
                : tickets.map(ticket => (
                    <>
                        <ListGroup data-order={ticket.id_order}>
                            <ListGroupItem data-order={ticket.id_order} onClick={handleCollapse} className="d-flex justify-content-between align-items-center cursor-pointer font-weight-bold py-0 px-1">
                                <Typography data-order={ticket.id_order} type="p" className="mt-2 p-1">
                                    <span data-order={ticket.id_order} className="mr-2 font-weight-bold"># {ticket.id_order}</span>
                                   Preparado a las <strong>{new Date(ticket.status.created_at).toLocaleTimeString()}</strong> por <strong>{ticket.status.user}</strong> un total de <strong>{Object.keys(ticket.products).length} productos</strong>
                                </Typography>
                                {collapsedOrders.includes(ticket.id_order.toString()) ?
                                    <MdKeyboardArrowUp data-order={ticket.id_order} className="cursor-pointer" size={30} />
                                    : <MdKeyboardArrowDown data-order={ticket.id_order} className="cursor-pointer" size={30} />}
                            </ListGroupItem>
                        </ListGroup>

                        <Collapse isOpen={collapsedOrders.includes(ticket.id_order.toString())}>
                            <SalesTicketTable ticket={ticket} />
                            <hr />
                        </Collapse>
                    </>
                ))}
        </div>
    )
}

export default SalesTicketContainer;