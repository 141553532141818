import React from 'react';
import axios from 'axios';
import { API } from 'configuration/axiosConfig';
import IframePreview from 'components/Common/IframePreview';

class Print {
  constructor(url) {
    this.url = url;
    this.file = null;
  }

  downloadFile = async (method = 'GET', auth = false, overwriteFile = false) => {
    try {
      if (!this.file || overwriteFile) {
        const options = {
          url: this.url,
          method,
          responseType: 'blob',
          xsrfCookieName: 'XSRF-TOKEN',
          xsrfHeaderName: 'X-XSRF-TOKEN',
        };

        let response = {};
        if (auth) {
          response = await API(options);
        } else {
          response = await axios(options);
        }

        this.file = response.data;
      }

      return this.file;

    } catch (error) {
      console.error(error);
    }
  };

  printFile = (type = 'application/pdf') => {
    const url = this.createObjectUrl(type);
    const iframe = document.createElement('iframe');

    iframe.setAttribute('src', url);
    iframe.setAttribute('style', 'visibility:hidden;');

    document.body.appendChild(iframe);

    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    iframe.contentWindow.close();

    window.URL.revokeObjectURL(url);
  };

  downloadAndPrint = (
    method = 'GET',
    type = 'application/pdf',
    auth = false,
  ) => {
    this.downloadFile(method, auth, true);
    this.printFile(type);
  };

  createObjectUrl = (type = 'application/pdf', file = null) => {
    return window.URL.createObjectURL(new Blob([file || this.file], { type }));
  };

  createFilePreview = (type = 'application/pdf', file = null) => {
    let src = this.createObjectUrl(type, file);

    return <IframePreview src={src} />;
  };
}



export default Print;