import { AsyncStorage } from './';

const lowerAndReplaceSpecialCharacters = (text = '') =>
  text
    .trim()
    .toLocaleLowerCase()
    .replace(/(\s|-)/g, '');

const capitalizeFirstLetter = text =>
  text.charAt(0).toUpperCase() + text.slice(1);

const avoidCopyPaste = event => {
  event.preventDefault();
  event.nativeEvent.stopImmediatePropagation();
  alert('La funcionalidad de copiar códigos de producto ha sido deshabilitada');
};

const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

const getRandomColor = () => {
  const letters = '0123456789ABCDEF'.split('');
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const randomNumberBetween = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const utf8Decode = utf8String => {
  if (typeof utf8String != 'string')
    throw new TypeError('parameter ‘utf8String’ is not a string');
  // note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
  const unicodeString = utf8String
    .replace(
      /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g, // 3-byte chars
      function (c) {
        // (note parentheses for precedence)
        var cc =
          ((c.charCodeAt(0) & 0x0f) << 12) |
          ((c.charCodeAt(1) & 0x3f) << 6) |
          (c.charCodeAt(2) & 0x3f);
        return String.fromCharCode(cc);
      },
    )
    .replace(
      /[\u00c0-\u00df][\u0080-\u00bf]/g, // 2-byte chars
      function (c) {
        // (note parentheses for precedence)
        var cc = ((c.charCodeAt(0) & 0x1f) << 6) | (c.charCodeAt(1) & 0x3f);
        return String.fromCharCode(cc);
      },
    );
  return unicodeString;
};

const utf8Encode = unicodeString => {
  if (typeof unicodeString != 'string')
    throw new TypeError('parameter ‘unicodeString’ is not a string');
  const utf8String = unicodeString
    .replace(
      /[\u0080-\u07ff]/g, // U+0080 - U+07FF => 2 bytes 110yyyyy, 10zzzzzz
      function (c) {
        var cc = c.charCodeAt(0);
        return String.fromCharCode(0xc0 | (cc >> 6), 0x80 | (cc & 0x3f));
      },
    )
    .replace(
      /[\u0800-\uffff]/g, // U+0800 - U+FFFF => 3 bytes 1110xxxx, 10yyyyyy, 10zzzzzz
      function (c) {
        var cc = c.charCodeAt(0);
        return String.fromCharCode(
          0xe0 | (cc >> 12),
          0x80 | ((cc >> 6) & 0x3f),
          0x80 | (cc & 0x3f),
        );
      },
    );
  return utf8String;
};

const removeAccents = str =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const isTabletOrMobile = () => {
  const device = navigator.userAgent || navigator.vendor || window.opera;

  const isTabletOrMobile =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i.test(
      device,
    );

  return isTabletOrMobile;
};

const getCachedItem = async (key, session = null) => {
  const cached = await AsyncStorage.AsyncSessionStorage.getItem(key);

  if (cached && session) {
    try {
      const cachedData = JSON.parse(cached);

      return cachedData;
    } catch (error) {
      console.error(error);
      //Por si acaso el JSON.parse tira error de tipado con el contenido de cached
      return null;
    }
  }
};

const readableFileSize = size => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let i = 0;
  while (size >= 1024) {
    size /= 1024;
    ++i;
  }
  return size.toFixed(1) + ' ' + units[i];
};

export default {
  readableFileSize,
  randomNumberBetween,
  lowerAndReplaceSpecialCharacters,
  getCachedItem,
  isTabletOrMobile,
  removeAccents,
  utf8Decode,
  utf8Encode,
  capitalizeFirstLetter,
  getRandomColor,
  avoidCopyPaste,
  dataURLtoFile,
};
