import ApiRepository from './ApiRepository';

export default class AuthService extends ApiRepository {
  constructor() {
    super();
  }

  async login(email, password) {
    return await this.request(this.POST, {
      url: '/login',
      data: { email, password },
    });
  }

  async logout() {
    return await this.request(this.POST, {
      url: '/logout',
      data: {},
    });
  }

  async forgotPassword(email) {
    return await this.request(this.POST, {
      url: '/forgot',
      data: { email },
    });
  }

  async resetPassword({ token, email, password, password_confirmation }) {
    return await this.request(this.POST, {
      url: '/password-reset',
      data: { token, email, password, password_confirmation },
    });
  }

  async refreshToken(params = {}) {
    return await this.request(this.POST, {
      url: '/refresh-token',
      data: { ...params },
    });
  }
}
