import React, { Component } from 'react'
import { Card, CardHeader, CardTitle, CardBody, Form, FormGroup, Button, Label, UncontrolledAlert } from 'reactstrap';
import Typography from 'components/Typography';
import NumericInput from 'react-numeric-input';
import { ConfigurationService } from 'services';

const translations = {
    'BIG_BOXES_AVAILABLE': 'CAJAS GRANDES',
    'MEDIUM_BOXES_AVAILABLE': 'CAJAS MEDIANAS',
    'SMALL_BOXES_AVAILABLE': 'CAJAS PEQUEÑAS',
    'MINI_BOXES_AVAILABLE' : 'CAJAS MINI'
}

class BoxStock extends Component {
    state = {
        alertMessage: "",
        alertType: "",
        loading: false,
        stock: {}
    }

    componentDidMount() {
        if (this.props.context.client) {
            const boxes = {}
            const nameRegexp = new RegExp(/(BOXES)/, 'i');

            for (let configuration_name in this.props.context.client.related_configurations) {
                if (nameRegexp.test(configuration_name)) {
                    boxes[configuration_name] = this.props.context.client.related_configurations[configuration_name];
                }
            }

            this.setState({
                stock: {
                    ...boxes
                }
            })
        }
    }

    handleStockChange = (valueAsNumber, valueAsString, input) => {
        this.setState({
            stock: {
                ...this.state.stock,
                [input.name]: {
                    ...this.state.stock[input.name],
                    value: valueAsNumber
                }
            }
        })
    }

    handleBoxStockSubmit = async (event) => {
        event.preventDefault();

        this.setState({ loading: true });

        const configuration_update = {}

        Object.keys(this.state.stock).forEach(size => {
            configuration_update[size] = this.state.stock[size]['value'];
        })

        const configurationService = new ConfigurationService();
        const response = await configurationService.updateMultipleValues({
            configuration_update
        });

        if (response.success) {
            this.setState({
                alertMessage: response.message,
                alertType: response.success ? 'success' : 'danger',
                loading: false
            });
        } else {
            console.error(response.message);

            this.setState({
                alertMessage: response.message,
                alertType: 'danger',
                loading: false,
            });
        }


    }

    createBoxStockHandler = () => {
        if (Object.keys(this.state.stock).length > 0) {
            return (
                <Form onSubmit={this.handleBoxStockSubmit}>
                    {this.state.alertMessage && <UncontrolledAlert color={this.state.alertType}>{this.state.alertMessage}</UncontrolledAlert>}
                    {Object.keys(this.state.stock).map(size => (
                        <FormGroup key={size}>
                            <Label for={size}>{translations[size]}</Label>
                            <NumericInput
                                className="form-control"
                                id={size}
                                name={size}
                                min={0}
                                max={9999}
                                type="number"
                                mobile
                                value={this.state.stock[size]['value']}
                                onChange={this.handleStockChange}
                                onPointerCancel={event => event.preventDefault()}
                                onTouchEnd={event => event.preventDefault()}
                                style={{
                                    wrap: {
                                        background: '#E2E2E2',
                                        boxShadow: '0 0 1px 1px #fff inset, 1px 1px 5px -1px #000',
                                        padding: '2px 0 2px 2px',
                                        borderRadius: '6px 3px 3px 6px',
                                        fontSize: 32
                                    },
                                    input: {
                                        borderRadius: '4px 2px 2px 4px',
                                        padding: '0.1ex 1ex 0.1ex 1.4ex !important',
                                        border: '1px solid #ccc',
                                        marginRight: 4,
                                        display: 'block',
                                        fontWeight: 100,
                                        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)'
                                    },
                                    'input:focus': {
                                        border: '1px inset #69C',
                                        outline: 'none'
                                    },
                                    arrowUp: {
                                        borderBottomColor: 'rgba(66, 54, 0, 0.63)'
                                    },
                                    arrowDown: {
                                        borderTopColor: 'rgba(66, 54, 0, 0.63)'
                                    }
                                }}
                            />
                        </FormGroup>
                    ))}
                    <Button className="mt-5" size="lg" color="primary" type="submit" disabled={this.state.loading} block>{this.state.loading ? 'Actualizando...' : 'Actualizar stock'}</Button>
                </Form>)
        } else {
            return <Typography type="lead">Recuperando stock de cajas...</Typography>
        }
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    <CardTitle>Gestionar Stock de cajas</CardTitle>
                    <CardBody>
                        {this.createBoxStockHandler()}
                    </CardBody>
                </CardHeader>
            </Card>
        )
    }
}


export default BoxStock;