import { GlobalContext } from 'Global_Context/context';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { TokenUtils } from 'utils';

const routeMapByRole = new Map();

routeMapByRole.set('operator', '/operator/scanner');
routeMapByRole.set('classified_operator', '/operator/cell/scanner');
routeMapByRole.set('supermarket_operator', '/operator/supermarket');
routeMapByRole.set('admin', '/dashboard');
routeMapByRole.set('superadmin', '/dashboard');

let PrivateRoute = ({
  component: Component,
  layout: Layout,
  path,
  exact,
  ...routeProps
}) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={props => {
        const session = TokenUtils.isUserAuthorized(routeProps.allowedRoles);

        /**
         * Nos deshacemos de la propiedad staticContext porque da problemas en el render
         * y tampoco la usamos en nuestra app.
         */
        const { staticContext, ...properties } = props;

        return session ? (
          <GlobalContext.Consumer>
            {context => (
              <Layout  context={context} {...properties}>
                <Component
                  {...properties}
                  context={context}
                />
              </Layout>
            )}
          </GlobalContext.Consumer>
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

let PublicRoute = ({
  component: Component,
  layout: Layout,
  path,
  exact,
  ...routeProps
}) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={props => {
        const session = TokenUtils.getTokenFromSession();

        /**
         * Nos deshacemos de la propiedad staticContext porque da problemas en el render
         * y tampoco la usamos en nuestra app.
         */

        const { staticContext, ...properties } = props;

        return !session ? (
          <GlobalContext.Consumer>
            {context => (
              <Layout>
                <Component {...properties} context={context} />
              </Layout>
            )}
          </GlobalContext.Consumer>
        ) : (
          <Redirect
            to={{
              pathname: routeMapByRole.get(session.payload.roles),
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export { PublicRoute, PrivateRoute };
