import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import Typography from '../Typography';
import Logo from '../Common/Logo';
import acaceLogo  from '../../assets/img/logo-simple-amarillo-lq.png';

const NotFound = props => {
  return props.location.pathname === '/' ? (
    <Redirect to={{ pathname: '/login', from: props.location }} />
  ) : (
    <Container className="mt-5">
      <Row className="text-center">
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <Logo src={acaceLogo} alt="404_image" />
          <Typography type="h1" data-testid="notfound-header">404 - Not Found</Typography>

          <p className="text-dark" data-testid="notfound-route">
            {window.location.origin}
            <span className="text-danger">{window.location.pathname}</span>
          </p>

          <Typography type="lead">
            La ruta que has puesto en la barra de direcciones
            <strong> no existe dentro de esta aplicación web</strong>
          </Typography>
          <Button color="warning" size="lg" onClick={() => props.history.push('/')}>Volver atrás</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
