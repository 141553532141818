import ApiRepository from './ApiRepository';

export default class ProductService extends ApiRepository {
  constructor() {
    super();
  }

  async statusUpdate(params) {
    return await this.request(this.POST, {
      url: `/product-status/update/${params['order_id']}`,
      data: {
        ...params,
      },
    });
  }

  async resetProductStatuses(params) {
    return await this.request(this.POST, {
      url: `/product-status/reset/${params['order_id']}`,
      data: {
        ...params,
      },
    });
  }

  async statusUpdateSimple(params) {
    return await this.request(this.PATCH, {
      url: `/product-status/update-simple/${params['productId']}`,
      data: {
        ...params,
      },
    });
  }

  async multipleUpdate(params) {
    return await this.request(this.PATCH, {
      url: '/product/multiple/update',
      data: {
        ...params,
      },
    });
  }

  async delete(id) {
    return await this.request(this.DELETE, {
      url: `product/${id}`,
    });
  }
}
