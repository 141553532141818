import React, { Component } from 'react'
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Page from 'components/Page';
import { BoxStock, BoxStats } from '../../BoxStock'
import ConfigurationManagement from 'pages/AdminDashboard/ConfigurationManagement/ConfigurationManagement.jsx';
import { BLACKLIST_PRODUCTS, PRODUCT_MANUFACTURERS_GUANXE, REEXPEDITION_LETTERS } from 'components/Common/Constants';
class ObySabinaAdmin extends Component {
    render() {
        return (
            <Page
                className="CustomAdminObySabina"
                title="Panel personalizado ObySabina"
                breadcrumbs={[{ name: 'Panel ObySabina ', active: true }]}>

                <Breadcrumb>
                    <BreadcrumbItem active><h4>Gestion de stock</h4></BreadcrumbItem>
                </Breadcrumb>
                <Row>
                    <Col xs={12} sm={10} md={6} lg={6} xl={5}>
                        <BoxStock context={this.props.context} />
                    </Col>
                    <Col xs={12} sm={10} md={6} lg={6} xl={7}>
                        <BoxStats client={this.props.context.client} />
                    </Col>
                </Row>
                <Breadcrumb>
                    <BreadcrumbItem active><h4>Parámetros configuración</h4></BreadcrumbItem>
                </Breadcrumb>
                <Row>
                    <ConfigurationManagement client={this.props.context.client} configurations={[BLACKLIST_PRODUCTS, REEXPEDITION_LETTERS, PRODUCT_MANUFACTURERS_GUANXE]} />
                </Row>
            </Page>
        )
    }
}


export default ObySabinaAdmin;