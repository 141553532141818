import React, { Component } from 'react'
import { Row, Col, Breadcrumb, BreadcrumbItem, FormGroup } from 'reactstrap';
import Page from 'components/Page';
import WarehouseContainer from './WarehouseContainer';
import { AdminService } from 'services';

class TuTrebolWarehouses extends Component {
    state = {
        almacenes: []
    }

    async componentDidMount() {
        const adminService = new AdminService();
        const response = await adminService.getClientWarehouses(this.props.context['session']['payload']['id_client']);

        if (response.success) {
            this.setState({ almacenes: response.warehouses });
        } else {
            this.setState({ almacenes: [] })
        }

    }

    render() {
        return (
            <Page
                className="CustomAdminTuTrebol"
                title="Panel personalizado TuTrebol"
                breadcrumbs={[{ name: 'Panel TuTrebol ', active: true }]}>

                <Breadcrumb>
                    <BreadcrumbItem active><h4>Gestión Almacenes</h4></BreadcrumbItem>
                </Breadcrumb>
                <Row>
                    <Col xs={12}>
                        <WarehouseContainer context={this.props.context} almacenes={this.state.almacenes} />
                    </Col>
                </Row>
            </Page>
        )
    }
}


export default TuTrebolWarehouses;