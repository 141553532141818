import React from 'react'
import PropTypes from 'prop-types';

const Logo = ({ src, alt, onLogoClick }) => {
    return (
        <div className="text-center pb-4">
            <img
                src={src}
                className="rounded img-fluid"
                alt={alt}
                onClick={onLogoClick}
            />
        </div>
    )
}

Logo.propTypes = {
    src: PropTypes.string.isRequired,
    onLogoClick: PropTypes.func,
    alt: PropTypes.string
}

Logo.defaultProps = {
  alt: "logo_example"
}

export default Logo;
