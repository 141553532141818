const React = require("react");

const SkeletonLoader = ({ type }) => {

    const renderPlaceholderType = () => {
        let placeholder = "";

        switch (type) {
            case "simple":
                placeholder = (
                    <div className="ph-item">
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-12 big"></div>
                                <div className="ph-col-12 big"></div>
                                <div className="ph-col-12 big"></div>
                                <div className="ph-col-12 big"></div>
                            </div>
                        </div>
                    </div>)
                break;

            case "simple_variant":
                placeholder = (
                    <div className="ph-item">
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-12 big"></div>
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8"></div>
                                <div className="ph-col-6 big"></div>
                                <div className="ph-col-2 empty big"></div>
                                <div className="ph-col-4 big"></div>
                            </div>
                        </div>
                    </div>)
                break;

            case "complex":
                placeholder = (
                    <div className="ph-item">
                        <div className="ph-col-12">
                            <div className="ph-picture"></div>
                        </div>
                        <div>
                            <div className="ph-row">
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-6"></div>
                                <div className="ph-col-6 empty"></div>
                                <div className="ph-col-2"></div>
                                <div className="ph-col-10 empty"></div>
                            </div>
                        </div>
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-10 big"></div>
                                <div className="ph-col-2 empty big"></div>
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-6"></div>
                                <div className="ph-col-6 empty"></div>
                                <div className="ph-col-12"></div>
                            </div>
                        </div>
                    </div>
                )
                break;

            case "multiple_card":
                placeholder = (
                    <div className="ph-item">
                        <div className="ph-col-12">
                            <div className="ph-picture"></div>
                        </div>
                        <div className="ph-col-6">
                            <div className="ph-picture"></div>
                            <div className="ph-row">
                                <div className="ph-col-4">
                                    <div className="ph-col-12"></div>
                                </div>
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-12"></div>
                            </div>
                        </div>

                        <div className="ph-col-6">
                            <div className="ph-picture"></div>
                            <div className="ph-row">

                                <div className="ph-col-4">
                                    <div className="ph-col-12"></div>
                                </div>
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-12"></div>
                            </div>
                        </div>

                    </div>
                )
                break;

            default:
                placeholder = (
                    <div className="ph-item">
                        <div className="ph-col-2">
                            <div className="ph-avatar"></div>
                        </div>
                        <div>
                            <div className="ph-row">
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-6"></div>
                                <div className="ph-col-6 empty"></div>
                                <div className="ph-col-2"></div>
                                <div className="ph-col-10 empty"></div>
                            </div>
                        </div>
                        <div className="ph-col-12">
                            <div className="ph-picture"></div>
                            <div className="ph-row">
                                <div className="ph-col-10 big"></div>
                                <div className="ph-col-2 empty big"></div>
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-6"></div>
                                <div className="ph-col-6 empty"></div>
                                <div className="ph-col-12"></div>
                            </div>
                        </div>
                    </div>)
                break;
        }

        return placeholder;
    }

    return renderPlaceholderType();


}

export default SkeletonLoader;