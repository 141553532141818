import React, { Component } from 'react';
import PropTypes from 'prop-types';
import fscreen from 'fscreen';
import LoadingOverlay from 'react-loading-overlay';
import ClientLogo from 'components/Common/ClientLogo';
import GlobalAlert from 'components/Errors/GlobalAlert';
import ZPLPrinterSelector from 'components/Common/ZPLPrinterSelector';
import Notifications from 'components/Notifications';
import withBadge from 'hocs/withBadge';
import Avatar from 'components/Avatar';

import { ClipLoader } from 'react-spinners';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
  MdFullscreen,
  MdFullscreenExit,
  MdBluetooth,
  MdBluetoothConnected,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import { UserCard } from 'components/Card';
import { bemnames as bn } from 'utils';
import { UserService } from 'services';
import { FaBluetooth } from 'react-icons/fa';

const bem = bn.bemNames.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})(MdNotificationsActive);

const userService = new UserService();
class Header extends Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: true,
    isOpenUserCardPopover: false,
    notifications: [],
    unreadNotifications: [],
    zplPrinter: null,
    fullscreen: false,
    loading: false,
    globalError: false,
  };

  componentDidMount() {
    // this.getNotifications();
  }

  getNotifications = async () => {
    const response = await userService.notifications();

    if (response.success) {
      this.setState({
        notifications: response.notifications,
        unreadNotifications: response.unread_notifications,
      });
    }
  };

  markAsReadNotifications = async event => {
    const response = await userService.markAsReadNotifications();

    if (response.success) {
      this.setState({
        notifications: response.notifications,
        unreadNotifications: response.unread_notifications,
      });
    }
  };

  requestFullScreen = event => {
    if (fscreen.fullscreenElement == null) {
      this.setState({ fullscreen: true });
      fscreen.requestFullscreen(document.documentElement);
    } else {
      this.setState({ fullscreen: false });
      fscreen.exitFullscreen();
    }
  };

  toggleNotificationPopover = () => {
    if (this.state.isOpenNotificationPopover) {
      this.markAsReadNotifications();
    }

    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  logout = async () => {
    const { context, history } = this.props;

    this.setState({ loading: true });

    await context.authLogout(history);

    this.setState({ loading: false });
  };

  showGlobalError = context => {
    return context.error.alertMessage ? (
      <GlobalAlert
        className="fixed-alert"
        success={context.error.alertType}
        isOpen={!!context.error.alertType}
        toggle={context.cleanGlobalError}
      >
        {context.error.alertMessage}
      </GlobalAlert>
    ) : null;
  };

  connectBluetoothDevice = async event => {
    const error = await this.props.context.services.bluetoothManager.connect();

    if (error) {
      this.props.context.createGlobalAlert(error, 'warning');
    } else {
      this.props.context.cleanGlobalError();
    }
  };

  showAvailableServices = () => {
    const { context } = this.props;

    const serviceComponent = (
      <>
        {context.services.bluetoothManager &&
          (context.services.bluetoothManager.isDeviceConnected() ? (
            <MdBluetoothConnected
              size={30}
              className="bluetooth-icon cursor-pointer"
              color="blue"
              onClick={this.connectBluetoothDevice}
            />
          ) : (
            <MdBluetooth
              size={30}
              className="bluetooth-icon cursor-pointer"
              color="lightgray"
              onClick={this.connectBluetoothDevice}
            />
          ))}
        {context.services.printers.zplPrinter ? (
          <ZPLPrinterSelector
            printer={context.services.printers.zplPrinter}
            error={context.services.printers.zplPrinterError}
          >
            <GlobalAlert
              className="fixed-alert"
              success="danger"
              isOpen={!!context.services.printers.zplPrinterError}
              toggle={context.cleanGlobalError}
            >
              {context.services.printers.zplPrinterError}
            </GlobalAlert>
          </ZPLPrinterSelector>
        ) : (
          'Sincronizando impresoras...'
        )}
      </>
    );

    if (context.services.bluetoothManager) {
      context.services.bluetoothManager.initDebug();
    }

    return serviceComponent;
  };

  render() {
    const { isNotificationConfirmed } = this.state;
    const { context, showSidebar, extraComponent, history } = this.props;

    return (
      <Navbar light expand className={bem.b('bg-white')}>
        {showSidebar && (
          <Nav navbar className="mr-2">
            <Button outline onClick={this.handleSidebarControlButton}>
              <MdClearAll size={25} />
            </Button>
          </Nav>
        )}
        <Button
          color="link"
          onClick={event => {
            event.preventDefault();
            return history.push('/');
          }}
        >
          Volver atrás
        </Button>

        {extraComponent}

        <Button
          className="mx-1 py-1"
          color="primary"
          outline
          onClick={this.requestFullScreen}
        >
          {this.state.fullscreen ? (
            <MdFullscreenExit size={30} />
          ) : (
            <MdFullscreen size={30} />
          )}
        </Button>
        {context.client && <ClientLogo client={context.client} />}

        <Nav navbar className={bem.e('nav-right')}>
          {this.showGlobalError(context)}
          {this.showAvailableServices()}

          <NavItem className="d-inline-flex">
            <NavLink id="Popover1" className="position-relative">
              {this.state.unreadNotifications.length > 0 ? (
                <MdNotificationsActiveWithBadge
                  size={25}
                  className="text-secondary can-click animated swing infinite"
                  onClick={this.toggleNotificationPopover}
                  notifications_amount={this.state.unreadNotifications.length}
                />
              ) : (
                <MdNotificationsNone
                  size={25}
                  className="text-secondary can-click"
                  onClick={this.toggleNotificationPopover}
                />
              )}
            </NavLink>
            <Popover
              placement="bottom"
              isOpen={this.state.isOpenNotificationPopover}
              toggle={this.toggleNotificationPopover}
              target="Popover1"
            >
              <PopoverBody>
                <Notifications
                  notifications={this.state.notifications}
                  unreadNotifications={this.state.unreadNotifications}
                />
              </PopoverBody>
            </Popover>
          </NavItem>

          <NavItem>
            <NavLink id="Popover2">
              <Avatar
                onClick={this.toggleUserCardPopover}
                className="can-click"
              />
            </NavLink>
            <Popover
              onTouchEnd={event => event.preventDefault()}
              onPointerDown={event => event.preventDefault()}
              onPointerCancel={event => event.preventDefault()}
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <LoadingOverlay
                  active={this.state.loading}
                  spinner={<ClipLoader />}
                >
                  <UserCard session={context.session} className="border-light">
                    <ListGroup flush>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light"
                      >
                        <MdPersonPin /> Perfil
                      </ListGroupItem>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light"
                      >
                        <MdInsertChart /> Estadísticas
                      </ListGroupItem>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light"
                      >
                        <MdMessage /> Mensajes
                      </ListGroupItem>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light"
                      >
                        <MdSettingsApplications /> Opciones
                      </ListGroupItem>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light"
                      >
                        <MdHelp /> Ayuda
                      </ListGroupItem>
                      <ListGroupItem
                        onPointerDown={this.logout}
                        onPointerCancel={event => event.preventDefault()}
                        onTouchEnd={event => event.preventDefault()}
                        tag="button"
                        action
                        className="border-light"
                      >
                        <MdExitToApp /> Cerrar sesión
                      </ListGroupItem>
                    </ListGroup>
                  </UserCard>
                </LoadingOverlay>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

Header.propTypes = {
  showSidebar: PropTypes.bool,
};

Header.defaultProps = {
  showSidebar: true,
  extraComponent: null,
};

export default Header;
