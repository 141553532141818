import React from 'react';
import { Button, Media } from 'reactstrap';
import Header from '../Header';
import Avatar from 'components/Avatar';

const StatusHeader = ({ lastStep}) => (
  <React.Fragment>
    {!!lastStep && (
      <Media className="m-2 ml-5 mr-4 status-header">
        <Media left className="mr-2">
          <Avatar />
        </Media>
        <Media body>
          <Media heading tag="h6" className="m-0">
            {lastStep.user.name}
          </Media>
          <p className="text-muted m-0">
            <small>{lastStep.diff_human_date}</small>
          </p>
        </Media>
        <Media right className="align-self-center">
          <Button color="link" className="text-capitalize text-primary">
            {lastStep.step_translated}
          </Button>
        </Media>
      </Media>
    )}
  </React.Fragment>
);

const ManagementHeader = ({ history, context }) => {
  const steps = context.selected_order['management_order_status'] || [];

  const lastStep =
    steps.length &&
    steps.find(
      step_data => step_data.status === 'ACTIVE' || step_data.step === 'close',
    );

  return (
    <header className="mb-0 navbar-header-container">
      <Header
        history={history}
        context={context}
        showSidebar={false}
        extraComponent={<StatusHeader lastStep={lastStep} />}
      />
    </header>
  );
};

export default ManagementHeader;
