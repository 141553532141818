import React, { useEffect, useState } from 'react'
import { BarLoader } from 'react-spinners';
import { Col, Form, Table, Input, Button, Alert } from 'reactstrap';
import { AdminService } from 'services';
import { BLACKLIST_PRODUCTS, REEXPEDITION_LETTERS, PRODUCT_MANUFACTURERS_GUANXE } from 'components/Common/Constants';

const adminService = new AdminService()

const ConfigurationManagement = ({ client, configurations = [] }) => {
    const [blackListForm, setBlackListForm] = useState({});
    const [lettersForm, setLettersForm] = useState({});
    const [manufacturersGuanxeForm, setManufacturersGuanxeForm] = useState({});

    const [configuration, setConfiguration] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);

    const [alert, setAlert] = useState({ key: '', type: '', message: '' })

    useEffect(() => {
        (async () => {
            if (client && !configuration) {
                setFetchLoading(true);

                const customConfiguration = await adminService.getCustomConfiguration(client.id);

                const blacklist_products = BLACKLIST_PRODUCTS.toLowerCase();
                const reexpedition_letters = REEXPEDITION_LETTERS.toLowerCase();
                const product_manufacturers_guanxe = PRODUCT_MANUFACTURERS_GUANXE.toLowerCase();

                setConfiguration(customConfiguration);

                if (configurations.includes(BLACKLIST_PRODUCTS)) {
                    setBlackListForm(customConfiguration[blacklist_products])
                }

                if (configurations.includes(REEXPEDITION_LETTERS)) {
                    setLettersForm(customConfiguration[reexpedition_letters])
                }


                if (configurations.includes(PRODUCT_MANUFACTURERS_GUANXE)) {
                    setManufacturersGuanxeForm(customConfiguration[product_manufacturers_guanxe])
                }

                setFetchLoading(false);
            }
        })()
    }, [client])


    const handleInputChange = (event) => {
        if (event.target.id.includes('blackListForm')) {
            const key = event.target.id.split('-')[1];
            const index = event.target.name;

            const blackListFormCopy = [...blackListForm]

            blackListFormCopy[index][key] = event.target.value;

            setBlackListForm(blackListFormCopy);
        } else if (event.target.id.includes('lettersForm')) {
            const key = event.target.id.split('-')[1];
            const index = event.target.name;

            const lettersFormCopy = [...lettersForm]

            lettersFormCopy[index][key] = event.target.value;

            setLettersForm(lettersFormCopy);
        }

        else if (event.target.id.includes('manufacturersGuanxeForm')) {
            const key = event.target.id.split('-')[1];
            const index = event.target.name;

            const manufacturersGuanxeFormCopy = [...manufacturersGuanxeForm]

            manufacturersGuanxeFormCopy[index][key] = event.target.value;

            setManufacturersGuanxeForm(manufacturersGuanxeFormCopy);
        }
    }

    const deleteItem = (event) => {

        const item = JSON.parse(event.target.getAttribute('data-item'));

        if (event.target.name.includes('blackListForm')) {

            if (window.confirm(`¿Está seguro de borrar la fila con los datos "${item.code} - ${item.type}"`)) {
                const index = event.target.id;
                const blackListFormCopy = [...blackListForm]

                blackListFormCopy.splice(index, 1)

                setBlackListForm(blackListFormCopy)
                updateConfiguration({ config_name: BLACKLIST_PRODUCTS, config_value: blackListFormCopy });
            }

        } else if (event.target.name.includes('lettersForm')) {
            if (window.confirm(`¿Está seguro de borrar la fila con los datos ${item.letter} - ${item.type}`)) {
                const index = event.target.id;
                const lettersFormCopy = [...lettersForm]

                lettersFormCopy.splice(index, 1)

                setLettersForm(lettersFormCopy)

                updateConfiguration({ config_name: REEXPEDITION_LETTERS, config_value: lettersFormCopy });

            }
        } else if (event.target.name.includes('manufacturersGuanxeForm')) {
            if (window.confirm(`¿Está seguro de borrar la fila con los datos ${item.code} - ${item.type}`)) {
                const index = event.target.id;
                const manufacturersGuanxeFormCopy = [...manufacturersGuanxeForm]

                manufacturersGuanxeFormCopy.splice(index, 1)

                setManufacturersGuanxeForm(manufacturersGuanxeFormCopy)

                updateConfiguration({ config_name: PRODUCT_MANUFACTURERS_GUANXE, config_value: manufacturersGuanxeFormCopy });

            }
        }
    }

    const addNewRow = (target_form) => {
        if (target_form.includes('blackListForm')) {
            setBlackListForm([...blackListForm, { code: '', type: '' }])
        } else if (target_form.includes('lettersForm')) {
            setLettersForm([...lettersForm, { letter: '', type: '' }])
        } else if (target_form.includes('manufacturersGuanxeForm')) {
            setManufacturersGuanxeForm([...manufacturersGuanxeForm, { code: '', type: '' }])
        }

    }

    const updateConfiguration = async (data) => {
        setAlert({ type: '', message: '' });
        setSubmitLoading(data.config_name);

        const response = await adminService.updateCustomConfiguration(client.id, data)

        setAlert({ key: data.config_name, type: response.success ? 'success' : 'danger', message: response.message })
        setSubmitLoading(false);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const config_name = event.target.id;
        let config_value = {};

        switch (config_name) {
            case BLACKLIST_PRODUCTS:
                config_value = blackListForm;
                break;

            case REEXPEDITION_LETTERS:
                config_value = lettersForm;
                break;

            case PRODUCT_MANUFACTURERS_GUANXE:
                config_value = manufacturersGuanxeForm;
                break;
        }

        updateConfiguration({
            config_name,
            config_value
        });
    }

    return (
        <>
            {configurations.includes(BLACKLIST_PRODUCTS) && (
                <Col xs={12} md={configurations.length === 1 ? 12 : 6}>
                    {alert.key === BLACKLIST_PRODUCTS && alert.message && <Alert color={alert.type}>{alert.message}</Alert>}
                    <Form id={BLACKLIST_PRODUCTS} autoComplete="off" onSubmit={handleSubmit}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Descripción</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="3">
                                        <Button disabled={Object.keys(blackListForm).length === 0} block color="primary" type="submit">
                                            {submitLoading === BLACKLIST_PRODUCTS ?
                                                <BarLoader loading width="100%" /> :
                                                'Actualizar códigos producto'
                                            }
                                        </Button>

                                    </td>
                                </tr>
                                <tr>
                                    <td><Input disabled placeholder="..." /></td>
                                    <td><Input disabled placeholder="..." /></td>
                                    <td><Button block color="success" type="button" onClick={(event) => addNewRow('blackListForm')}>Nuevo</Button></td>
                                </tr>
                                {fetchLoading && <tr><td colSpan="3"><h5>Cargando códigos de producto...</h5></td></tr>}
                                {Object.keys(blackListForm).length > 0 && blackListForm.map(({ code, type }, index) => {
                                    return <tr key={index}>
                                        <td>
                                            <Input
                                                autoFocus
                                                key={`code-${index}`}
                                                id="blackListForm-code"
                                                name={index}
                                                onChange={handleInputChange}
                                                minLength="1"
                                                maxLength="50"
                                                type="text"
                                                value={blackListForm[index]['code']}
                                                required
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                key={`type-${index}`}
                                                id="blackListForm-type"
                                                name={index}
                                                onChange={handleInputChange}
                                                minLength="1"
                                                maxLength="50"
                                                type="text"
                                                value={blackListForm[index]['type']}
                                                required
                                            />
                                        </td>
                                        <td>
                                            <Button id={index} data-item={JSON.stringify({ code, type })} name="blackListForm" block type="button" color="danger" onClick={deleteItem}>
                                                Borrar
                                            </Button>
                                        </td>
                                    </tr>
                                })}

                            </tbody>

                        </Table>
                    </Form>
                </Col>
            )}

            {configurations.includes(REEXPEDITION_LETTERS) && (
                <Col xs={12} md={configurations.length === 1 ? 12 : 6}>
                    {alert.key === REEXPEDITION_LETTERS && alert.message && <Alert color={alert.type}>{alert.message}</Alert>}

                    <Form id={REEXPEDITION_LETTERS} autoComplete="off" onSubmit={handleSubmit}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Letras</th>
                                    <th>Tipo</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="3">
                                        <Button disabled={Object.keys(lettersForm).length === 0} block color="primary" type="submit">
                                            {submitLoading === REEXPEDITION_LETTERS ?
                                                <BarLoader loading width="100%" /> : 'Actualizar letras reenvío'
                                            }
                                        </Button>

                                    </td>
                                </tr>
                                <tr>
                                    <td><Input disabled placeholder="..." /></td>
                                    <td><Input disabled placeholder="..." /></td>
                                    <td><Button block color="success" type="button" onClick={(event) => addNewRow('lettersForm')}>Nuevo</Button></td>
                                </tr>
                                {fetchLoading && <tr><td colSpan="3"><h5>Cargando letras reenvío...</h5></td></tr>}

                                {Object.keys(lettersForm).length > 0 && lettersForm.map(({ letter, type }, index) => {
                                    return (
                                        <tr key={index + 10}>
                                            <td>
                                                <Input
                                                    id="lettersForm-letter"
                                                    key={`letter-${index}`}
                                                    name={index}
                                                    onChange={handleInputChange}
                                                    minLength="1"
                                                    maxLength="20"
                                                    type="text"
                                                    value={lettersForm[index]['letter']}
                                                    required
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    id="lettersForm-type"
                                                    key={`type-${index}`}
                                                    name={index}
                                                    onChange={handleInputChange}
                                                    minLength="1"
                                                    maxLength="30"
                                                    type="text"
                                                    value={lettersForm[index]['type']}
                                                    required
                                                />
                                            </td>
                                            <td>
                                                <Button id={index} data-item={JSON.stringify({ letter, type })} name="lettersForm" block type="button" color="danger" onClick={deleteItem}>
                                                    Borrar
                                            </Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Form>
                </Col>)}

            {configurations.includes(PRODUCT_MANUFACTURERS_GUANXE) && (
                <Col xs={12} md={configurations.length === 1 ? 12 : 6}>
                    {alert.key === PRODUCT_MANUFACTURERS_GUANXE && alert.message && <Alert color={alert.type}>{alert.message}</Alert>}

                    <Form id={PRODUCT_MANUFACTURERS_GUANXE} autoComplete="off" onSubmit={handleSubmit}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Marca</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="3">
                                        <Button disabled={Object.keys(lettersForm).length === 0} block color="primary" type="submit">
                                            {submitLoading === PRODUCT_MANUFACTURERS_GUANXE ?
                                                <BarLoader loading width="100%" /> : 'Actualizar códigos Guanxe'
                                            }
                                        </Button>

                                    </td>
                                </tr>
                                <tr>
                                    <td><Input disabled placeholder="..." /></td>
                                    <td><Input disabled placeholder="..." /></td>
                                    <td><Button block color="success" type="button" onClick={(event) => addNewRow('manufacturersGuanxeForm')}>Nuevo</Button></td>
                                </tr>
                                {fetchLoading && <tr><td colSpan="3"><h5>Cargando códigos guanxe...</h5></td></tr>}

                                {Object.keys(manufacturersGuanxeForm).length > 0 && manufacturersGuanxeForm.map(({ code, type }, index) => {
                                    return (
                                        <tr key={index + 10}>
                                            <td>
                                                <Input
                                                    id="manufacturersGuanxeForm-code"
                                                    key={`code-${index}`}
                                                    name={index}
                                                    onChange={handleInputChange}
                                                    minLength="1"
                                                    maxLength="20"
                                                    type="text"
                                                    value={manufacturersGuanxeForm[index]['code']}
                                                    required
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    id="manufacturersGuanxeForm-type"
                                                    key={`type-${index}`}
                                                    name={index}
                                                    onChange={handleInputChange}
                                                    minLength="1"
                                                    maxLength="30"
                                                    type="text"
                                                    value={manufacturersGuanxeForm[index]['type']}
                                                    required
                                                />
                                            </td>
                                            <td>
                                                <Button id={index} data-item={JSON.stringify({ code, type })} name="manufacturersGuanxeForm" block type="button" color="danger" onClick={deleteItem}>
                                                    Borrar
                                            </Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Form>
                </Col>
            )}

        </>
    )
}



export default ConfigurationManagement
