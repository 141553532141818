const getJsonFromUrl = (url = '') => {
  if (!url) return {};

  const query = url.substr(1);

  const result = {};

  query.split('&').forEach(function (part) {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });

  return result;
};

/**
 * Ejemplo  /operator/order/190311/ --> operator/order/190331
 * @param {String} pathname
 */
const removePathSlashes = (pathname = '') => {
  const regex = /^\/|\/$/;

  if (!pathname) {
    return window.location.pathname.replace(regex, '');
  }

  return pathname.replace(regex, '');
};

export default { removePathSlashes, getJsonFromUrl };
