import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar, NavItem, NavLink as BSNavLink } from 'reactstrap';
import SourceLink from 'components/SourceLink';
import acaceLogo from 'assets/img/logo-simple-amarillo-lq.png';
import { clientOptions } from 'configuration/ClientOptions/';
import { bemnames as bn, TokenUtils } from 'utils';

const bem = bn.bemNames.create('sidebar');
class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
    clientOptions: {
      navContents: [],
    },
  };

  componentDidMount() {
    this.setState({ clientOptions: clientOptions(this.props.client) });
  }

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    const { client } = this.props;
    const { clientOptions } = this.state;

    return (
      <aside className={bem.b()}>
        <div className={bem.e('background')} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src={acaceLogo}
                className="img-fluid max-width-100"
                alt="acace_logo"
              />
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {client &&
              clientOptions.navContents.map(
                ({ to, name, exact, Icon, allowed }, index) => {
                  return (
                    TokenUtils.isUserAuthorized(allowed) && (
                      <NavItem key={index} className={bem.e('nav-item')}>
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          className="text-uppercase"
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                        >
                          <Icon className={bem.e('nav-item-icon')} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    )
                  );
                },
              )}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
