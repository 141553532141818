import Pusher from 'pusher-js';

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
});

const pusherChannel = process.env.REACT_APP_PUSHER_CHANNEL;
const channel = pusher.subscribe(pusherChannel);

const bindEventToChannel = (name, callback) => {
  channel.bind(name, callback);

  return channel;
};

const unBindEventsFromChannel = events =>
  events.forEach(event => channel.unbind(event));

export default { unBindEventsFromChannel, bindEventToChannel };
