import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from './Guards';
import GlobalContextProvider from './Global_Context/context';

import ErrorBoundary from 'components/Errors/ErrorBoundary';
import NotFound from 'components/Errors/NotFound';

import { EmptyLayout, MainLayout } from 'components/Layout';
import ManagementLayout from 'components/Layout/ManagementLayout/ManagementLayout';

import {
  SabinaStoreAdmin,
  ObySabinaAdmin,
  TXTAdmin,
  MosfashionAdmin,
  TuTrebolAdmin,
  TuTrebolWarehouses,
} from './pages/AdminDashboard/CustomClientAdmin';

import ScrollToTop from 'components/Common/ScrollToTop';
import PageSpinner from 'components/PageSpinner';
import { TokenUtils } from 'utils';

import 'react-confirm-alert/src/react-confirm-alert.css';
import './styles/reduction.scss';

const AuthPage = React.lazy(() => import('./pages/AuthPage'));

const DashboardPage = React.lazy(() =>
  import('./pages/AdminDashboard/DashboardPage'),
);
const ManageUsersPage = React.lazy(() =>
  import('./pages/AdminDashboard/ManageUsersPage'),
);
const ConfigurationPage = React.lazy(() =>
  import('./pages/AdminDashboard/ConfigurationPage'),
);
const SuperAdminPage = React.lazy(() =>
  import('./pages/AdminDashboard/SuperAdminPage'),
);
const StatsPage = React.lazy(() => import('./pages/AdminDashboard/StatsPage'));

const StepEditorPage = React.lazy(() =>
  import('./pages/AdminDashboard/StepEditorPage'),
);

const PackageListPage = React.lazy(() =>
  import('./pages/AdminDashboard/PackageListPage'),
);

const OperatorScanPage = React.lazy(() =>
  import('./pages/OperatorManagement/OperatorScanPage'),
);
const OperatorOrderPage = React.lazy(() =>
  import('./pages/OperatorManagement/OperatorOrderPage'),
);

const CellOperatorPage = React.lazy(() =>
  import('pages/Cells/CellOperatorPage'),
);

const SupermarketPage = React.lazy(() =>
  import('pages/Supermarket/SupermarketPage'),
);
const SupermarketScannerPage = React.lazy(() =>
  import('pages/Supermarket/SupermarketScannerPage'),
);

class App extends React.Component {

  render() {
    return (
      <GlobalContextProvider>
        <ScrollToTop>
          <ErrorBoundary>
            <React.Suspense fallback={<PageSpinner />}>
              <Switch key={this.props.location.key}>
                <PublicRoute
                  exact
                  layout={EmptyLayout}
                  path={['/login', '/forgot', '/password-reset']}
                  component={props => <AuthPage {...props} />}
                />

                <PrivateRoute
                  layout={MainLayout}
                  exact
                  path="/dashboard"
                  component={DashboardPage}
                  allowedRoles={['superadmin', 'admin']}
                />

                <PrivateRoute
                  layout={MainLayout}
                  exact
                  path="/dashboard/manage-users"
                  component={ManageUsersPage}
                  allowedRoles={['superadmin', 'admin']}
                />

                <PrivateRoute
                  layout={MainLayout}
                  exact
                  path="/dashboard/configuration"
                  component={ConfigurationPage}
                  allowedRoles={['superadmin', 'admin']}
                />

                <PrivateRoute
                  layout={MainLayout}
                  exact
                  path="/dashboard/stats"
                  component={StatsPage}
                  allowedRoles={['superadmin', 'admin']}
                />

                <PrivateRoute
                  layout={MainLayout}
                  exact
                  path="/dashboard/editor"
                  component={StepEditorPage}
                  allowedRoles={['superadmin', 'admin']}
                />

                <PrivateRoute
                  layout={MainLayout}
                  exact
                  path="/dashboard/package-lists"
                  component={PackageListPage}
                  allowedRoles={['superadmin', 'admin']}
                />

                <PrivateRoute
                  layout={MainLayout}
                  exact
                  path="/dashboard/custom/sabinastore"
                  component={SabinaStoreAdmin}
                  allowedRoles={['superadmin', 'admin']}
                />

                <PrivateRoute
                  layout={MainLayout}
                  exact
                  path="/dashboard/custom/obysabina"
                  component={ObySabinaAdmin}
                  allowedRoles={['superadmin', 'admin']}
                />

                <PrivateRoute
                  layout={MainLayout}
                  exact
                  path="/dashboard/custom/txt"
                  component={TXTAdmin}
                  allowedRoles={['superadmin', 'admin']}
                />

                <PrivateRoute
                  layout={MainLayout}
                  exact
                  path="/dashboard/custom/mosfashion"
                  component={MosfashionAdmin}
                  allowedRoles={['superadmin', 'admin']}
                />

                <PrivateRoute
                  layout={MainLayout}
                  exact
                  path="/dashboard/custom/tutrebol"
                  component={TuTrebolAdmin}
                  allowedRoles={['superadmin', 'admin']}
                />

                <PrivateRoute
                    layout={MainLayout}
                    exact
                    path="/dashboard/custom/tutrebol/warehouse"
                    component={TuTrebolWarehouses}
                    allowedRoles={['superadmin', 'admin', 'supermarket_operator']}
                />

                {TokenUtils.isUserAuthorized(['superadmin']) && (
                  <PrivateRoute
                    layout={MainLayout}
                    exact
                    path="/dashboard/superadmin"
                    component={SuperAdminPage}
                    allowedRoles={['superadmin']}
                  />
                )}

                <PrivateRoute
                  layout={ManagementLayout}
                  exact
                  path="/operator/scanner"
                  component={OperatorScanPage}
                  allowedRoles={['superadmin', 'admin', 'operator']}
                />

                <PrivateRoute
                  layout={ManagementLayout}
                  exact
                  path="/operator/order/:id"
                  component={OperatorOrderPage}
                  allowedRoles={['superadmin', 'admin', 'operator']}
                />

                <PrivateRoute
                  layout={ManagementLayout}
                  exact
                  path="/operator/cell/scanner"
                  component={CellOperatorPage}
                  allowedRoles={['admin', 'classified_operator']}
                />

                <PrivateRoute
                  layout={ManagementLayout}
                  exact
                  path="/operator/supermarket"
                  component={SupermarketPage}
                  allowedRoles={['superadmin','admin', 'supermarket_operator']}
                />

                <PrivateRoute
                  layout={ManagementLayout}
                  exact
                  path="/operator/supermarket/scanner"
                  component={SupermarketScannerPage}
                  allowedRoles={['admin', 'supermarket_operator']}
                />

                <Route component={NotFound} />
              </Switch>
            </React.Suspense>
          </ErrorBoundary>
        </ScrollToTop>
      </GlobalContextProvider>
    );
  }
}

export default withRouter(App);
