import buylopez from './buylopez';
import sabinastore from './sabinastore';
import obysabina from './obysabina';
import txt from './txt';
import mosfashion from './mosfashion';
import tutrebol from './tutrebol';
import default_options from './default_options';

const clients = {
  buylopez,
  sabinastore,
  obysabina,
  mosfashion,
  txt,
  tutrebol,
};

export const clientOptions = client => {
  if (client) {
    const client_key = client['alias'].toLowerCase();

    if (clients[client_key]) {
      const clientOptions = { ...clients[client_key] };

      clientOptions['navContents'] = default_options['navContents'].concat(
        clientOptions['navContents'],
      );

      return clientOptions;
    }
  }

  return default_options;
};
