import React from 'react';
import componentQueries from 'react-component-queries';
import { breakpoints } from 'utils';
import { Content, Header, Sidebar } from 'components/Layout';

import 'react-confirm-alert/src/react-confirm-alert.css';

class MainLayout extends React.Component {

  componentWillReceiveProps({ breakpoint }) {
    if (breakpoint !== this.props.breakpoint) {
      this.checkBreakpoint(breakpoint);
    }
  }

  async componentDidMount() {
    this.checkBreakpoint(this.props.breakpoint);
  }

  isSidebarOpen() {
    return document
      .querySelector('.cr-sidebar')
      .classList.contains('cr-sidebar--open');
  }

  // close sidebar when
  handleContentClick = event => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (
      this.isSidebarOpen() &&
      (this.props.breakpoint === 'xs' ||
        this.props.breakpoint === 'sm' ||
        this.props.breakpoint === 'md')
    ) {
      this.openSidebar('close');
    }
  };

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return this.openSidebar('close');

      case 'lg':
      case 'xl':
      default:
        return this.openSidebar('open');
    }
  }

  openSidebar(openOrClose) {
    if (openOrClose === 'open') {
      return document
        .querySelector('.cr-sidebar')
        .classList.add('cr-sidebar--open');
    }
    document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
  }


  render() {
    const { children, history, context } = this.props;
    return (
      <main className="cr-app bg-light">
        <Sidebar history={history} client={context.client} />
        <Content fluid onClick={this.handleContentClick}>
          <Header history={history} context={context} />
          {children}
        </Content>
      </main>
    );
  }
}

export default componentQueries(breakpoints.query)(MainLayout);
