import React from 'react'
import { Button } from 'reactstrap';
import Select from 'react-select';
import { FaPrint } from 'react-icons/fa';
import { MdSync } from 'react-icons/md';


const ZPLPrinterSelector = ({ printer, children, error = null }) => {
    return (
        <>
            <div
                className="d-flex flex-start align-items-center mr-4"
                style={{ width: '355px' }}
            >
                <FaPrint size={30} className="mr-1" />
                <Select
                    className="w-100"
                    id="printers"
                    name="printers"
                    onChange={printer.onSelectedDevice}
                    options={printer.selector_options}
                    placeholder="Seleccione una impresora"
                    defaultValue={
                        !printer.selected_device
                            ? []
                            : [
                                {
                                    label: `${printer.selected_device.manufacturer} - ${printer.selected_device.name}`,
                                    value: printer.selected_device.uid,
                                },
                            ]
                    }
                />
                <Button color="success" onClick={printer.syncDevices}>
                    <MdSync size={20} />
                </Button>
            </div>
            {error && children}
        </>
    )
}


export default ZPLPrinterSelector;