import ApiRepository from './ApiRepository';

export default class ClientService extends ApiRepository {
  constructor() {
    super();
  }

  async getData(client_id, useCache = false) {
    return await this.request(this.GET, {
      url: `/client/index/${client_id}`,
      useCache
    });
  }

  async logisticOperators() {
    return await this.request(this.GET, {
      url: '/client/logistic-operators',
      useCache: false,
    });
  }

  async activeOrders() {
    return await this.request(this.GET, {
      url: '/client/active-orders',
    });
  }
}
