import React from 'react';
import { Container, Row} from 'reactstrap';
import Typography from '../Typography';

const Footer = () => {
  return (
    <div className="fixed-footer">
      <Container fluid>
         <Row className="d-flex flex-wrap justify-content-center">
           <Typography type="h6">
             &#169; {new Date().getFullYear()} ACACE - Gestor de pedidos {process.env.REACT_APP_SOFTWARE_VERSION}
           </Typography>
         </Row>
      </Container>
    </div>
  );
};

export default Footer;
