import ApiRepository from './ApiRepository';

export default class UserService extends ApiRepository {
  constructor() {
    super();
  }

  async sync() {
    return await this.request(this.GET, {
      url: '/user/sync',
    });
  }

  async notifications() {
    return await this.request(this.GET, {
      url: '/user/notifications',
    });
  }

  async all() {
    return await this.request(this.GET, {
      url: '/user/all',
      useCache: false,
    });
  }

  async create(params) {
    return await this.request(this.POST, {
      url: '/user/create',
      data: {
        ...params,
      },
    });
  }

  async markAsReadNotifications(params) {
    return await this.request(this.PUT, {
      url: `/user/notifications`,
      data: {
        ...params,
      },
    });
  }
  async update(user_id, params) {
    return await this.request(this.PATCH, {
      url: `/user/update/${user_id}`,
      data: {
        ...params,
      },
    });
  }

  async delete(user_id) {
    return await this.request(this.DELETE, {
      url: `/user/delete/${user_id}`,
    });
  }
}
