import { MdDevicesOther, MdHome } from 'react-icons/md';

const CLIENT_KEY = 'tutrebol';

export default {
  navContents: [
    {
      to: `/dashboard/custom/${CLIENT_KEY}`,
      name: 'Tickets venta',
      exact: true,
      Icon: MdDevicesOther,
      allowed: ['superadmin', 'admin'],
    },
    {
      to: `/dashboard/custom/${CLIENT_KEY}/warehouse`,
      name: 'Almacenes',
      exact: true,
      Icon: MdHome,
      allowed: ['superadmin', 'admin', 'supermarket_operator'],
    },
  ],
  RoutesForApp: [],
};
