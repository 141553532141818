import ApiRepository from './ApiRepository';

export default class ConfigurationService extends ApiRepository {
  constructor() {
    super();
  }

  async getValues() {
    return await this.request(this.GET, {
      url: '/configurations',
      useCache: true,
    });
  }

  async enabledGlobalPackage(url_params = '') {
    return await this.request(this.GET, {
      url: '/configuration/enabled-global-package' + url_params,
    });
  }

  async updateValue(params) {
    return await this.request(this.PATCH, {
      url: '/configurations/update',
      data: {
        ...params,
      },
    });
  }

  async updateMultipleValues(params) {
    return await this.request(this.PATCH, {
      url: '/configurations/update-multiple',
      data: {
        ...params,
      },
    });
  }
}
