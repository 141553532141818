import React from 'react';
import { propTypes as PropTypes } from 'utils';
import { Media } from 'reactstrap';
import Avatar from 'components/Avatar';
import userImage from 'assets/img/users/default-user.png';

const Notifications = ({ notifications, unreadNotifications }) => {
  const notificationsToShow =
    unreadNotifications.length > 0 ? unreadNotifications : notifications;

  const renderMessageBasedOnType = notification => {
    if (notification.type.includes('AssignedOrder')) {
      if (notification.data.order.is_assigned) {
        return `El usuario ${notification.data.user.name} tiene asignado el pedido ${notification.data.order.id_order_prestashop} y se ha actualizado su estado a ${notification.data.order.spanish_status}`;
      }

      return `El pedido ${notification.data.order.id_order_prestashop} se ha liberado y está disponible para preparar`;
    }

    return notification.id;
  };

  return notificationsToShow.map(notification => (
    <Media key={notification.id} className="pb-2" onClick={event => null}>
      <Media left className="align-self-center pr-3">
        <Avatar tag={Media} object src={userImage} alt={notification.id} />
      </Media>
      <Media body middle className="align-self-center">
        {renderMessageBasedOnType(notification)}
      </Media>
      <Media right className="align-self-center">
        <small className="text-muted">{notification.human_readable_date}</small>
      </Media>
    </Media>
  ));
};

Notifications.propTypes = {
  notifications: PropTypes.array,
  unreadNotifications: PropTypes.array,
};

Notifications.defaultProps = {
  notifications: [],
  unreadNotifications: [],
};

export default Notifications;
