import React, { useState, useEffect } from 'react';
import SkeletonLoader from 'components/Common/SkeletonLoader';
import Typography from 'components/Typography';
import Select from 'react-select';
import { Alert, Col, Collapse, Form, FormGroup, Input, Button, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { WarehouseService } from 'services';
import { DateUtils } from 'utils';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdSearch } from 'react-icons/md';
import WarehouseTable from "./WarehouseTable";
import exampleFile from 'assets/files/ejemplo.csv';
import { FileUploadCustom } from 'components/Common/FileUploadCustom';


const WarehouseContainer = ({ context, almacenes }) => {
    const [alert, setAlert] = useState({ type: '', message: '' });
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({ warehouse: null });
    const [warehouse, setWarehouse] = useState({ warehouse: null });
    const [warehouses, setWarehouseEntries] = useState([]);
    const [collapsedWarehouses, setCollapsedWarehouses] = useState([]);
    const [file, setFile] = useState({file: null});

    useEffect(() => {
        fetchWarehouses();
    }, [form]);

    const fetchWarehouses = async (id_warehouse, id_client) => {
        setWarehouseEntries([]);
        setAlert({ type: '', message: '' })
        setLoading(true);

        const warehouseService = new WarehouseService();

        const response = await warehouseService.warehousesEntries(id_client || context['session']['payload']['id_client']);

        if (response.success) {
            setWarehouseEntries(response.warehouses || []);
            setAlert({ type: '', message: '' })

        } else {
            setWarehouseEntries([]);
            setAlert({ type: 'danger', message: response.message })
        }

        setLoading(false);
    }

    const handleWarehouseChange = (event) => {
        setWarehouse({warehouse: event.value});
    }

    const handleCollapse = (event) => {
        const id_warehouse = event.target.dataset.warehouse;

        if (collapsedWarehouses.includes(id_warehouse)) {
            setCollapsedWarehouses(collapsedWarehouses.filter(collapsed_id_warehouse => collapsed_id_warehouse !== id_warehouse));
        } else {
            setCollapsedWarehouses(collapsedWarehouses.concat([id_warehouse]));
        }
    }

    return (
        <div>
            <h4>Actualizar Almacenes</h4>
            <Form className="w-100" enctype="multipart/form-data">
                <Row>
                    <Col xs={12} md={5}>
                        <FormGroup>
                            <Label for="warehouse-selector">Listado de almacenes:</Label>
                            <Select
                                placeholder="Todos"
                                className="w-100 max-width-250"
                                id="warehouse-selector"
                                name="warehouse-selector"
                                options={almacenes && almacenes.map(almacen => ({ label: almacen.name, value: almacen.id }))}
                                onChange={handleWarehouseChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={2}>
                        <FormGroup>
                            <Label for="warehouse-ejemplo">Fichero de ejemplo:</Label>
                            <Button
                                active
                                block
                                size="sm"
                                color="primary"
                                id="warehouse-ejemplo"
                                name="warehouse-ejemplo"
                                href={exampleFile}
                            >
                                Descargar
                            </Button>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={5}>
                        <FormGroup>
                            <Label for="warehouse-file">Cargar Fichero:</Label>
                            <FileUploadCustom
                                title="Cargar"
                                url={`/supermarket/warehouse/${warehouse.warehouse}`}
                                warehouse={warehouse.warehouse}
                                onFinishUpload={() => fetchWarehouses()}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>

            {alert.type && (<Alert className="my-2 mx-1" color={alert.type}>{alert.message}</Alert>)}

            <br/>
            <h4>Entradas actuales de los almacenes</h4>

            {!loading && !warehouses.length && (
                <Typography type="lead" className="text-center my-3 mx-1">
                    <MdSearch size={30} className="mr-1"/>
                    <span>Debe de seleccionar un almacen</span>
                </Typography>)}

            {loading ? <SkeletonLoader type="complex"/>
                : warehouses.map(warehouse => (
                    <>
                        <ListGroup data-warehouse={warehouse.cod_almacen}>
                            <ListGroupItem data-warehouse={warehouse.cod_almacen} onClick={handleCollapse}
                                           className="d-flex justify-content-between align-items-center cursor-pointer font-weight-bold py-0 px-1">
                                <Typography data-warehouse={warehouse.cod_almacen} type="p" className="mt-2 p-1">
                                    <span data-warehouse={warehouse.cod_almacen}
                                          className="mr-2 font-weight-bold">{warehouse.nombre_almacen}
                                        {warehouse.direccion.length > 0 && (
                                        <span className="mr-2 font-weight-light">
                                            &nbsp;- ( {warehouse.direccion})
                                        </span>)}

                                    </span>
                                </Typography>
                                {collapsedWarehouses.includes(warehouse.cod_almacen.toString()) ?
                                    <MdKeyboardArrowUp data-warehouse={warehouse.cod_almacen} className="cursor-pointer"
                                                       size={30}/>
                                    : <MdKeyboardArrowDown data-warehouse={warehouse.cod_almacen} className="cursor-pointer"
                                                           size={30}/>}
                            </ListGroupItem>
                        </ListGroup>

                        <Collapse isOpen={collapsedWarehouses.includes(warehouse.cod_almacen.toString())}>
                            <WarehouseTable entries={warehouse.entries}/>
                            <hr/>
                        </Collapse>
                    </>
                ))}
        </div>
    )
}

export default WarehouseContainer;