import React, { Component } from 'react';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
  }

  render() {
     if (this.state.hasError) {
      return (
        <div id="NotFound">
          <div className="NotFound">
            <div className="NotFound-404">
              <h1>501</h1>
            </div>
            <h2>Oops! Ha sucedido un error inesperado</h2>
            <p>Hemos recibido el error, lo solventaremos lo mas rápido posible</p>
            <a href="/">Volver a la página de inicio</a>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
