import { MdDevicesOther } from 'react-icons/md';

const CLIENT_KEY = 'obysabina';

export default {
  navContents: [
    {
      to: `/dashboard/custom/${CLIENT_KEY}`,
      name: 'Opciones personalizadas',
      exact: true,
      Icon: MdDevicesOther,
      allowed: ['superadmin', 'admin'],
    },
  ],
  RoutesForApp: [],
};
