import ApiRepository from './ApiRepository';

export default class StepService extends ApiRepository {
  constructor() {
    super();
  }

  async generalStatistics() {
    return await this.request(this.GET, {
      url: '/step/statistics/general',
    });
  }

  async individualRecord(order_id) {
    return await this.request(this.GET, {
      url: `/step/record/${order_id}`,
    });
  }

  async periodReport(params) {
    return await this.request(this.POST, {
      url: '/step/statistics/period-report',
      data: {
          ...params
      }
    });
  }
}
