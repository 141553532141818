import React from 'react';
import ManagementHeader from './ManagementHeader';

const ManagementLayout = ({ children, context, history }) => {
  return (
    <>
      <ManagementHeader history={history} context={context} />
      {children}
    </>
  );
};

export default ManagementLayout;