import React from 'react'
import { Alert, Badge, ListGroup, ListGroupItem, Table } from 'reactstrap'
import Typography from 'components/Typography';


const TableHeaders = () => <thead>
    <tr>
        <th>Nombre del Producto</th>
        <th>Estado</th>
        <th>Cantidad</th>
        <th>Unitario</th>
        <th>Total</th>
    </tr>
</thead>

const TicketPriceHeader = ({ ticket }) => {

    const replacedTotalPrice = (ticket) => {
        let price = ticket.replaced_total_price['new'] - ticket.replaced_total_price['removed'];
        let color = 'primary';

        if (price > 0) {
            color = 'primary';
        } else if (price === 0) {
            price = '0.00';
            color = 'warning';
        } else {
            color = 'danger';
        }

        return <Badge className="font-size-1" color={color} pill>{`${price > 0 || price === '0.00' ? '+' : ''}${parseFloat(price).toFixed(2)}`} €</Badge>
    }

    const deliveryHours = hypermarket => {
        try {

            if (hypermarket) {
                const fromDate = new Date(hypermarket.date.from.date);
                const toDate = new Date(hypermarket.date.to.date);

                let fromDateMinutes = fromDate.getUTCMinutes();
                let toDateMinutes = toDate.getUTCMinutes();

                if (fromDateMinutes < 10) {
                    fromDateMinutes += '0';
                }

                if (toDateMinutes < 10) {
                    toDateMinutes += '0';
                }

                return `${fromDate.getUTCHours()}:${fromDateMinutes} - ${toDate.getUTCHours()}:${toDateMinutes}`;
            }

            return 'No definido';
        } catch (error) {
            return 'No definido';
        }
    };

    return <Alert color="primary" className="mb-0">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
            <Typography type="p">
                Entrega para código postal <strong>{ticket.postcode}</strong> en Horario: <strong>{deliveryHours(ticket.hypermarket)}</strong>
            </Typography>

            <ListGroup>
                <ListGroupItem>Añadidos: <Badge className="font-size-1 ml-2" color="success" pill>+{ticket.added_total_price} €</Badge></ListGroupItem>
                <ListGroupItem>Reemplazos: {replacedTotalPrice(ticket)}</ListGroupItem>
                <ListGroupItem>Parciales:  <Badge className="font-size-1" color={ticket.total_price_partial_validations > 0 ? 'danger' : 'warning'}>-{ticket.total_price_partial_validations} €</Badge></ListGroupItem>
                <ListGroupItem>Total original: <Badge className="font-size-1" color="primary" pill>{ticket.total_price_original} €</Badge></ListGroupItem>
                <ListGroupItem>Diferencia: <Badge className="font-size-1" color={ticket.total_price_difference.includes('-') ? 'danger' : 'warning'} pill>{ticket.total_price_difference} €</Badge></ListGroupItem>
                <ListGroupItem className="justify-content-between font-weight-bold">Precio final: <Badge className="font-size-1 ml-2" color="success" pill>{ticket.total_price} €</Badge></ListGroupItem>
            </ListGroup>
        </div>
    </Alert>

}

const TableProductRow = ({ product }) => {
    const showProductQuantityValidated = (product) => {
        if (product.quantity_validated < product.quantity) {
            return `(${product.quantity_validated}/${product.quantity})`
        }

        return product.quantity;
    }

    const showProductQuantityDiff = (product) => {
        if (product.was_quantity_diff && product.quantity_diff) {
            const result = product.quantity_diff.new - product.quantity_diff.original;

            return result;
        }

        return false;
    }

    const mapRowBasedOnStatus = product => {
        if (product.was_quantity_diff) {
            const difference = showProductQuantityDiff(product);
            const operatorToShow = difference > 0 ? `+ ${difference}` : difference.toString();
            const colorClass = `font-weight-bold ${operatorToShow.includes('+') ? 'success-color' : 'error-color'}`;
            const totalProductDifference = product.unit_price * difference;
            const originalTotalProductPrice = product.quantity_diff.original * product.unit_price;
            const newTotalProductPrice = originalTotalProductPrice + totalProductDifference

            return (
                <tr className="table-warning font-size-105">
                    <td>{product.name}</td>
                    <td>{product.status_to_show.toUpperCase()}</td>
                    <td>{product.quantity_diff.original} <span className={colorClass}>{operatorToShow}</span></td>
                    <td>{product.unit_price} €</td>
                    <td className="font-weight-bold">
                        {originalTotalProductPrice.toFixed(2)} <span className={colorClass}>{operatorToShow.includes('+') ? '+' : ''}{totalProductDifference}</span> = {newTotalProductPrice.toFixed(2)}€
                    </td>
                </tr>
            )
        }
        return (
            <>
                <tr className={product.was_added ?
                    'table-success font-size-105' : product.was_replaced || product.quantity_validated < product.quantity
                        ? 'table-warning font-size-105' : ''}>
                    <td>{product.name}</td>
                    <td>{product.status_to_show.toUpperCase()}</td>
                    <td>{showProductQuantityValidated(product)}</td>
                    <td>{product.unit_price} €</td>
                    <td className={product.was_added || product.was_replaced || product.status_to_show === 'Validado parcial' ? 'font-weight-bold' : ''}>
                        {product.was_added || product.was_replaced ? '+' : ''}{product.total_price_original} € {product.status_to_show === 'Validado parcial' && <span className="ml-1 error-color">-{(product.total_price_original - product.total_price_validated).toFixed(2)} = {product.total_price_validated} €</span>}
                    </td>
                </tr>
                {product.was_replaced && (
                    <tr className="table-danger font-size-105">
                        <td className="font-weight-bold">EL PRODUCTO ANTERIOR ERA:</td>
                        <td className="font-weight-bold">{product.diff_replaced.removed.name}</td>
                        <td>{product.diff_replaced.removed.quantity}</td>
                        <td>{product.diff_replaced.removed.unit_price}</td>
                        <td className="font-weight-bold">-{product.diff_replaced.removed.total_price_original} €</td>
                    </tr>)
                }
            </>)

    }

    return mapRowBasedOnStatus(product)
}


const SalesTicketTable = ({ ticket }) => (
    <>
        <TicketPriceHeader ticket={ticket} />
        <div className="overflow-vertical max-height-350 fixed-table-header">
            <Table responsive
                striped
                size="sm"
                className="my-2 w-100 p-relative border-collapse font-size-08" >
                <TableHeaders />
                <tbody>
                    {Object.keys(ticket.products).map(product_id => <TableProductRow key={product_id} product={ticket.products[product_id]} />)}
                </tbody>
            </Table>
        </div>

    </>
)

export default SalesTicketTable;