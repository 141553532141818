import ApiRepository from './ApiRepository';

export default class CustomerService extends ApiRepository {
  constructor() {
    super();
  }

  async getSeurLocationsByPostcode(postcode, iso_code_country) {
    return await this.request(this.GET, {
      url: `/customer/seur-locations/${postcode}/${iso_code_country}`,
    });
  }
}
