import { Common } from 'utils';

const PRINTER_SERVICE = {
  name: 'printer-service',
  uuid: '000018f0-0000-1000-8000-00805f9b34fb',
};

class BluetoothManager {
  constructor(options, callbacks = {}) {
    this.options = {
      tabletDebug: false,
      availableServices: [
        ...(options.availableServices || []),
        PRINTER_SERVICE,
      ],
      maxReconnectionTries: 2,
      ...options,
    };

    this.debug = this.tabletDebugMode(this.options.tabletDebug);

    this.connectedDevice = {
      device: {},
      gattServer: {},
      actions: {},
      maxReconnectionTries: this.options.maxReconnectionTries,
    };

    this.attachedEvents = [];
    this.callbacks = callbacks;
    this.reconnectionTries = 0;
  }

  tabletDebugMode(debug = false) {
    if (debug && Common.isTabletOrMobile()) {
      return require('eruda');
    }

    return false;
  }

  initDebug() {
    if (this.debug) {
      return this.debug.init();
    }

    return false;
  }

  isDeviceConnected() {
    return (
      this.connectedDevice.device &&
      Object.keys(this.connectedDevice.actions).length > 0 &&
      this.connectedDevice.actions.characteristics.length > 0
    );
  }

  isBluetoothAvailable() {
    return navigator && typeof navigator.bluetooth !== 'undefined';
  }

  getSpecificService(serviceName) {
    return this.options.availableServices.find(
      ({ name }) => name === serviceName,
    );
  }

  deviceHaveService(serviceToCheck = {}) {
    if (Object.keys(this.connectedDevice.actions).length > 0) {
      return (
        this.getSpecificService(serviceToCheck.name).uuid ===
        this.connectedDevice.actions.service.uuid
      );
    }

    return false;
  }

  async getDeviceServiceAndCharacteristics(
    serviceUUID,
    gattServer = this.connectedDevice.gattServer,
  ) {
    if (gattServer.connected && serviceUUID) {
      const service = await gattServer.getPrimaryService(serviceUUID);

      if (service) {
        const characteristics = await service.getCharacteristics();

        return { service, characteristics };
      }
    }

    return { service: null, characteristics: [] };
  }

  registerEventOnDevice(device, eventToAttach, callback) {
    if (
      Object.keys(device).length > 0 &&
      !this.attachedEvents.includes(eventToAttach)
    ) {
      device.addEventListener(eventToAttach, callback);

      this.attachedEvents = this.attachedEvents.concat([eventToAttach]);
    }
  }

  detachEventsOnDevice(device) {
    if (Object.keys(device).length > 0) {
      this.attachedEvents.forEach(attachedEvent =>
        device.removeEventListener(attachedEvent),
      );

      this.attachedEvents = [];
    }
  }

  async onDisconnect() {
    if (!this.isDeviceConnected()) return;

    try {
      if (this.reconnectionTries <= this.connectedDevice.maxReconnectionTries) {
        this.reconnectionTries++;

        return await this.connectedDevice.device.watchAdvertisements();
      }

      this.detachEventsOnDevice(this.connectedDevice.device);
    } catch (error) {
      console.error(error);

      return error;
    }
  }

  async onAdvertisementReceived() {
    try {
      await this.connect();
      await this.connectedDevice.device.unwatchAdvertisements();
    } catch (e) {
      // Continue listening for advertisements.
    }
  }

  async initializeGattServer(device, bluetoothService = 'printer-service') {
    const gattServer = await device.gatt.connect();

    if (gattServer.connected) {
      const selectedService = this.getSpecificService(bluetoothService);

      const actions = await this.getDeviceServiceAndCharacteristics(
        selectedService.uuid,
        gattServer,
      );

      this.connectedDevice = {
        ...this.connectedDevice,
        device,
        gattServer,
        actions,
      };

      this.registerEventOnDevice(
        device,
        'gattserverdisconnected',
        this.onDisconnect,
      );

      this.registerEventOnDevice(
        device,
        'advertisementreceived',
        this.onAdvertisementReceived,
      );
    } else {
      return 'Failed connecting to gatt server on device ' + device.name;
    }
  }

  async connect(bluetoothService = 'printer-service') {
    try {
      if (this.isBluetoothAvailable()) {
        const device = await navigator.bluetooth.requestDevice({
          filters: [
            {
              services: this.options.availableServices.map(({ uuid }) => uuid)
            },
            {
              namePrefix: 'Printer'
            },
          ],
        });

        if (device) {
          return await this.initializeGattServer(device, bluetoothService);
        }
      } else {
        return 'Bluetooth is not available, update your browser or enable it on your options';
      }
    } catch (error) {
      return `${error.name} - ${error.message}`;
    }
  }

  async sendDataToPrinter(buffer, MAX_DATA_SEND_SIZE = 20) {
    if (
      this.isDeviceConnected() &&
      this.deviceHaveService(PRINTER_SERVICE) &&
      buffer
    ) {
      let chunkCount = Math.ceil(buffer.byteLength / MAX_DATA_SEND_SIZE);
      let chunkIndex = 0;

      const chunks = [];

      while (chunkCount !== 0) {
        chunks.push(buffer.slice(chunkIndex, chunkIndex + MAX_DATA_SEND_SIZE));

        chunkIndex += MAX_DATA_SEND_SIZE;
        chunkCount--;
      }

      for (let index = 0; index < chunks.length; index++) {
        const writed = await this.writeToPrinter(
          this.connectedDevice.actions.characteristics[1],
          chunks[index],
        );

        if (typeof writed === 'string') {
          //@TODO MOSTRAR ERROR DE ESCRITURA
        }
      }

      return true;
    }

    return 'Este dispositivo bluetooth no es una impresora y no se pueden enviar datos de impresión';
  }

  async writeToPrinter(characteristic, buffer) {
    try {
      return await characteristic.writeValue(new DataView(buffer));
    } catch (error) {
      return `${error.name} - ${error.message}`;
    }
  }
}

export default BluetoothManager;
