import React from 'react'
import { Alert, Badge, ListGroup, ListGroupItem, Table } from 'reactstrap'
import Typography from 'components/Typography';


const TableHeaders = () => <thead>
    <tr>
        <th>Codigo Articulo</th>
        <th>Descripción</th>
        <th>Ubicacion</th>
        <th>Numero Caja</th>
    </tr>
</thead>

const EntryRow = ({ entry }) => {

    const mapRowBasedOnStatus = entry => {
        return (
            <>
                <tr className='table-success font-size-105'>
                    <td>{entry.cod_articulo}</td>
                    <td>{entry.desc_articulo}</td>
                    <td>{entry.ubicacion}</td>
                    <td>{entry.num_caja}</td>
                </tr>
            </>)

    }

    return mapRowBasedOnStatus(entry)
}


const EntriesTable = ({ entries }) => (
    <>
        <div className="overflow-vertical max-height-350 fixed-table-header">
            <Table responsive
                striped
                size="sm"
                className="my-2 w-100 p-relative border-collapse font-size-08" >
                <TableHeaders />
                <tbody>
                    {Object.keys(entries).map(id => <EntryRow key={id} entry={entries[id]} />)}
                </tbody>
            </Table>
        </div>
    </>
)

export default EntriesTable;