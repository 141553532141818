import { AsyncStorage } from './';
import { AuthService } from 'services';

const validateJWT = (jwt = null) => {
  if (!jwt) return null;

  return /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/.test(jwt);
};

const decodeJWTPayload = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

const getTokenFromSession = () => {
  const jwt = sessionStorage.getItem('token');

  if (!validateJWT(jwt)) {
    return null;
  }

  const payload = decodeJWTPayload(jwt);

  return { jwt, payload };
};

const isUserAuthorized = allowedRoles => {
  const session = getTokenFromSession();

  if (session) {
    let authorized = session.payload.roles
      .split(',')
      .some(role => allowedRoles.includes(role));

    return authorized ? session : false;
  }

  return false;
};
const setTokenOnSession = token => {
  return AsyncStorage.AsyncSessionStorage.setItem('token', token);
};

const removeTokenFromSession = () => {
  sessionStorage.removeItem('client');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('logistic_operators');
  sessionStorage.removeItem('assignedOrders');
  sessionStorage.removeItem('pendingOrders');
  sessionStorage.removeItem('preparedOrders');
};

const getResetToken = async () => {
  const authService = new AuthService();
  const response = authService.refreshToken({});

  if (response.success) {
    const resetToken = response.refreshed_token;
    await setTokenOnSession(response.refreshed_token);

    return resetToken;
  } else {
    console.error(response.message);
    return false;
  }
};

export default {
  getResetToken,
  removeTokenFromSession,
  getTokenFromSession,
  setTokenOnSession,
  validateJWT,
  isUserAuthorized,
  decodeJWTPayload,
};
