import {
  MdDvr,
  MdAccountBox,
  MdSettings,
  MdWidgets,
  MdShowChart,
  MdAssistant,
  MdMarkunreadMailbox,
} from 'react-icons/md';

export default {
  navContents: [
    {
      to: '/dashboard',
      name: 'Dashboard',
      exact: true,
      Icon: MdDvr,
      allowed: ['superadmin', 'admin'],
    },
    {
      to: '/dashboard/manage-users',
      name: 'Usuarios',
      exact: false,
      Icon: MdAccountBox,
      allowed: ['superadmin', 'admin'],
    },
    {
      to: '/dashboard/configuration',
      name: 'Configuracion',
      exact: false,
      Icon: MdSettings,
      allowed: ['superadmin', 'admin'],
    },
    {
      to: '/dashboard/stats',
      name: 'Estadísticas',
      exact: false,
      Icon: MdShowChart,
      allowed: ['superadmin', 'admin'],
    },
    {
      to: '/dashboard/editor',
      name: 'Asistente gestor',
      exact: false,
      Icon: MdAssistant,
      allowed: ['superadmin', 'admin'],
    },
    {
      to: `/dashboard/package-lists/`,
      name: 'Package list',
      exact: true,
      Icon: MdMarkunreadMailbox,
      allowed: ['superadmin', 'admin'],
    },
    {
      to: '/dashboard/superadmin',
      name: 'Superadmin',
      exact: false,
      Icon: MdWidgets,
      allowed: ['superadmin'],
    },
  ],
  RoutesForApp: [],
};
