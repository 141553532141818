/**
 {
   "deviceType":"printer",
   "uid":" 28J175000708",
   "provider":"com.zebra.ds.webdriver.desktop.provider.DefaultDeviceProvider",
   "name":" 28J175000708",
   "connection":"usb",
   "version":3,
   "manufacturer":"Zebra Technologies"
  }
 */
class ZPLPrint {
  constructor() {
    this.selected_device = null;
    this.devices = [];
    this.default_device_type = 'printer';
    this.selector_options = [];

    if (!this.devices.length) {
      this.syncDevices();
    }
  }

  storageValueOrDefault = key => {
    const defaultValues = {
      selected_device: null,
      devices: [],
      selector_options: [],
    };

    return JSON.parse(localStorage.getItem(key)) || defaultValues[key];
  };

  syncDevices = () => {
    window.BrowserPrint.getDefaultDevice(
      this.default_device_type,
      device => {
        this.selected_device = device;
        this.devices.push(device);

        window.BrowserPrint.getLocalDevices(
          device_list => {
            if (device_list.length) {
              device_list.forEach(device => {
                if (
                  !this.selected_device ||
                  device.uid !== this.selected_device.uid
                ) {
                  this.devices.push(device);
                }
              });

              this.devicesToSelectOptions();
            }
          },
          error => {
            if (!error) {
              alert('Error obteniendo las impresoras locales');
            }
            console.error(error);
            return alert(error);
          },
          this.default_device_type,
        );
      },
      error => {
        if (!error) {
          console.error(
            'Ha sucedido un error en la sincronización de impresoras, compruebe que están conectadas correctamente',
          );
          return true;
        }
        console.error(error);
        return error;
      },
    );
  };

  getSelectedDevice = () => {
    return this.selected_device;
  };

  getAllDevices = () => {
    return this.devices;
  };

  writeToSelectedPrinter = dataToWrite => {
    if (this.selected_device) {
      this.selected_device.send(dataToWrite, undefined, this.errorCallback);
    } else {
      return 'No hay ninguna impresora seleccionada';
    }
  };

  readCallback = readData => {
    if (!readData) {
      alert('No Response from Device');
    } else {
      alert(readData);
    }
  };
  errorCallback = errorMessage => {
    alert('Error: ' + errorMessage);
  };

  readFromSelectedPrinter = () => {
    this.selected_device.read(this.readCallback, this.errorCallback);
  };

  devicesToSelectOptions = () => {
    this.selector_options = this.devices.map(device => ({
      label: `${device.manufacturer} - ${device.name}`,
      value: device.uid,
    }));

    return this.selector_options;
  };

  onSelectedDevice = selected_device => {
    this.selected_device = this.devices.find(
      device => device.uid === selected_device.value,
    );
  };
}

export default ZPLPrint;
