import axios from 'axios';
import {
  throttleAdapterEnhancer,
  cacheAdapterEnhancer,
} from 'axios-extensions';
import { UrlUtils, TokenUtils } from 'utils';

const ApiRepository = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter, {
      enabledByDefault: false,
      cacheFlag: 'useCache',
    }),
  ),
});

ApiRepository.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Añade el token automaticamente en cada request
 */
ApiRepository.interceptors.request.use(
  function (config) {
    const session = TokenUtils.getTokenFromSession();

    if (session) {
      config['headers']['Authorization'] = `Bearer ${session.jwt}`;
      const pathname = UrlUtils.removePathSlashes(window.location.pathname);

      if (pathname && pathname.includes('operator')) {
        config['headers']['Actual-Route'] = pathname;
      }
    }

    return config;
  },
  error => {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  },
);

/**
 * Este interceptor refresca el token cuando este ha expirado para
 * una mejor experiencia de usuario
 */
ApiRepository.interceptors.response.use(
  response => {
    // If the request succeeds, we don't have to do anything and just return the response
    return response;
  },
  error => {
    const errorResponse = error.response;

    if (isTokenExpiredError(errorResponse)) {
      return resetTokenAndReattemptRequest(error);
    }

    triggerLogOut(error);

    // If the error is due to other reasons, we just throw it back to axios
    return new Promise((resolve, reject) => {
      reject(error);
    });
  },
);

function isTokenExpiredError(errorResponse) {
  return (
    errorResponse &&
    errorResponse.data &&
    (errorResponse.data.message === 'Token has expired' ||
      errorResponse.data.message === 'Token not provided')
  );
}

async function resetTokenAndReattemptRequest(error) {
  try {
    const { response: errorResponse } = error;
    const resetToken = await TokenUtils.getResetToken(); // Your own mechanism to get the refresh token to refresh the JWT token

    if (!resetToken) {
      // We can't refresh, throw the error anyway
      return Promise.reject(error);
    }

    errorResponse['config']['headers'][
      'Authorization'
    ] = `Bearer ${resetToken}`;

    return new Promise((resolve, reject) => {
      axios
        .request(errorResponse.config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export const isSuccessFullResponse = status => [200, 201, 204].includes(status);

export const triggerLogOut = error => {
  if (error.response && [401, 403, 419].includes(error.response.status)) {
    if (typeof window !== 'undefined') {
      // logOut();
    }
  }

  return true;
};
export const handleApiError = error => {
  if (error.response) {
    if (error.response.data.errors) {
      const errors = error.response.data.errors;

      return Object.values(errors)
        .map(message => message)
        .join(', ');
    }

    if (!error.response.data.message) {
      if (error.response.data.error) {
        return error.response.data.error;
      }

      return "Error inesperado en el servidor, inténtelo de nuevo";
    }

    return `${error.response.data.message} \n\r  ${
      error.response.data.detail || ''
    }`;
  } else if (error.message) return error.message;
  else return 'Error inesperado en el servidor, inténtelo de nuevo';
};

export { ApiRepository };
