import ApiRepository from './ApiRepository';

export default class CellService extends ApiRepository {
  constructor() {
    super();
  }

  async warehousesEntries(id_client) {
    return await this.request(this.GET, {
      url: `/supermarket/warehouses/${id_client}`,
    });
  }

  async uploadWarehouseFile(id_warehouse, params) {
    return await this.request(this.POST, {
      url: `/supermarket/warehouse/${id_warehouse}`,
      data: {
        ...params,
      },
    });
  }

}
