export const BLACKLIST_PRODUCTS = 'BLACKLIST_PRODUCTS';
export const REEXPEDITION_LETTERS = 'REEXPEDITION_LETTERS';
export const PRODUCT_MANUFACTURERS_GUANXE = 'PRODUCT_MANUFACTURERS_GUANXE';

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const PATCH = 'PATCH';
export const DELETE = 'DELETE';

