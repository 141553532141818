import React from 'react';

const IframePreview = ({ src }) => {
    return (
        <div className="embed-responsive embed-responsive-16by9" style={{ height: '85%' }}>
            <link rel="preload" href={src} as="document"></link>
            <iframe title="print-tag" className="embed-responsive-item management-iframe" src={src} allowFullScreen></iframe>
        </div>
    );
};

export default IframePreview;
