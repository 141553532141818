import React, { Component, Fragment } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Container, Alert } from 'reactstrap'
import { AdminService } from 'services'
import { Common } from 'utils'
import { ClipLoader } from 'react-spinners'
import Typography from 'components/Typography'

class BoxStats extends Component {
    state = {
        data: {},
        alertMessage: '',
        alertType: ''
    }


    componentDidUpdate(prevProps) {
        if (!prevProps.client && this.props.client) {
            this.fetchBoxStockStats();
        }
    }

    fetchBoxStockStats = async () => {
        try {
            const adminService = new AdminService();

            const response = await adminService.boxStockStats(this.props.client.id);

            if (response.success) {
                const { stats, labels } = response;
                const colors = labels.map(label => Common.getRandomColor());

                this.setState({
                    data: {
                        datasets: [{
                            data: stats,
                            backgroundColor: colors,
                            hoverBackgroundColor: colors
                        }],
                        labels
                    }
                })
            } else {
                this.setState({
                    data: {},
                    alertMessage: 'No hay datos disponibles para mostrar',
                    alertType: 'warning'
                })
            }
        } catch (error) {
            this.setState({
                data: {},
                alertMessage: error,
                alertType: 'danger'
            })
        }
    }

    render() {
        return (
            <Container>
                {this.state.alertMessage && <Alert color={this.state.alertType}>{this.state.alertMessage}</Alert>}
                {!this.state.alertMessage && Object.keys(this.state.data).length === 0 ?
                    <Fragment><ClipLoader loading /> <Typography type="lead">Obteniendo estadísticas...</Typography> </Fragment>: (
                        <Doughnut
                            data={this.state.data}
                            options={{
                                responsive: true,
                                maintainAspectRatio: true,
                                "display": true,
                                "position": "top",
                                "fullWidth": true,
                                "reverse": false,
                            }}
                            redraw
                            type="pie"
                            width={300}
                        />
                    )}

            </Container>
        )
    }
}

export default BoxStats;