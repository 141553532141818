import React, { Component, Fragment } from 'react'
import { AdminService } from 'services';
import { Label } from 'reactstrap';
import Select from 'react-select';

class ClientSelector extends Component {
    state = {
        clients: [],
        client_options: [],
        selected_client: {},
        stock: [],
        server_error: ''
    }

    componentDidMount() {
        this.getClientsWithWarehouses();
    }

    getClientsWithWarehouses = async () => {
        const adminService = new AdminService();
        const response = await adminService.fetchClientsWithWarehouses();

        if (response.success) {
            const client_options = response.clients.map(data => ({
                label: data['alias'],
                value: data['id'],
            }));

            this.setState({
                client_options,
                clients: response.clients
            });
        } else {
            this.setState({
                clients: [],
                client_options: [],
                server_error: response.message,
            });
        }
    };

    handleClientsSelectChange = async (values) => {
        this.setState({ selected_client: values })

        const selected_client = this.state.clients.find(client => client.id === values.value)
        this.props.updateSelectedClient(selected_client)

        this.props.fetchStockData(selected_client);
    }

    render() {
        return (
            <Fragment>
                <Label for="clients">Clientes asociados a TXT</Label>
                <Select
                    className="mb-2"
                    id="client"
                    onChange={this.handleClientsSelectChange}
                    options={this.state.client_options}
                />
            </Fragment>
        )
    }
}




export default ClientSelector;
