import ApiRepository from './ApiRepository';

export default class OrderService extends ApiRepository {
  constructor() {
    super();
  }

  async getByReference(order_reference) {
    return await this.request(this.GET, {
      url: `/order/${order_reference}`,
    });
  }

  async findOrdersFromScanner(order_reference) {
    return await this.request(this.POST, {
      url: '/order',
      data: {
        order_reference,
      },
    });
  }

  async createTag(params) {
    return await this.request(this.POST, {
      url: '/order/tag/create',
      data: {
        ...params,
      },
    });
  }

  async validateTag(params) {
    return await this.request(this.POST, {
      url: `/tag/validate/${params['order_id']}`,
      data: {
        ...params,
      },
    });
  }

  async updateSteps({
    order_reference,
    action = 'initialize',
    step_id = null,
    signal = null,
    tag_validation_value = '',
  }) {
    return await this.request(this.POST, {
      url: `/order/update-steps/${order_reference}`,
      data: {
        action,
        step_id,
        signal,
        tag_validation_value,
      },
    });
  }

  async updateOrderStatus(order_id, params) {
    return await this.request(this.PUT, {
      url: `/order/${order_id}/status`,
      data: {
        ...params,
      },
    });
  }

  async updateOrder(order_id, params) {
    return await this.request(this.PATCH, {
      url: `/order/${order_id}`,
      data: {
        ...params,
      },
    });
  }

  async removeProduct(productId) {
    return await this.request(this.DELETE, {
      url: `/order/product/${productId}`,
    });
  }
}
