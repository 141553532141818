import React, { useState } from 'react'
import { FileService } from 'services';
import {Alert, Button, Col, FormGroup, Input, Label, Row} from 'reactstrap';

export const FileUploadCustom = ({ url = '', title = 'Subir archivo', warehouse, onFinishUpload }) => {

    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);

    const [message, setMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    const selectFile = (event) => {
        setSelectedFiles(event.target.files || event.dataTransfer.files);
    };

    const upload = () => {
        if(warehouse != null && warehouse != undefined && warehouse != "" && warehouse != 0){
            const fileService = new FileService();

            let currentFile = selectedFiles[0];

            setProgress(0);
            setCurrentFile(currentFile);

            fileService.upload(currentFile, url, (event) => {
                setProgress(Math.round((100 * event.loaded) / event.total));
            })
                .then((response) => {
                    if(response.success){
                        setMessage(response.message);
                        setProgress(0);
                        setAlertType('success');
                        setCurrentFile(undefined);
                        onFinishUpload(response);
                    } else {
                        setMessage(response.message);
                        setProgress(0);
                        setAlertType('danger');
                        setCurrentFile(undefined);
                    }
                    setTimeout(() => {setAlertType(""); setMessage("");}, 3000);
                })
                .catch((error) => {
                    setProgress(0);
                    setMessage('Ha sucedido un error en el proceso');
                    setAlertType('danger');
                    setCurrentFile(undefined);
                    setTimeout(() => {setAlertType(""); setMessage("");}, 3000);
                });

            setSelectedFiles(undefined);
        } else {
            setMessage('Debe de elegir un almacén para subir el archivo');
            setAlertType('danger');
            setTimeout(() => {setAlertType(""); setMessage("");}, 3000);
        }
    }

    return (
        <Row>
            {currentFile && !message && (
                <Col xs={12} md={9}>
                    <FormGroup>
                        <div className="progress">
                            <div
                                className="progress-bar progress-bar-info progress-bar-striped"
                                role="progressbar"
                                aria-valuenow={progress}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{width: progress + "%"}}
                            >
                                {progress}%
                            </div>
                        </div>
                    </FormGroup>
                </Col>
            )}

            {!currentFile && !message && (
                <Col xs={12} md={9}>
                    <FormGroup>
                        <Input
                            accept=".csv"
                            type="file"
                            name="warehouse-file"
                            onChange={selectFile}
                        />
                    </FormGroup>
                </Col>
            )}

            {!currentFile && !message && (
                <Col xs={12} md={3}>
                    <FormGroup>
                        <Button
                            disabled={!selectedFiles}
                            block
                            size="sm"
                            color="success"
                            id="warehouse-update"
                            name="warehouse-update"
                            onClick={upload}
                        >
                            {title}
                        </Button>
                    </FormGroup>
                </Col>
            )}

            {message &&
                <Col xs={12} md={12}>
                    <Alert color={alertType}>
                        {message}
                    </Alert>
                </Col>
            }

        </Row>
    );

}
