import { API } from 'configuration/axiosConfig';
import { GET, POST, PUT, PATCH, DELETE } from 'components/Common/Constants';
import { handleApiError } from 'configuration/API/ApiRepository';

export default class ApiRepository {
  constructor() {
    this.API = API;
    this.handleApiError = handleApiError;

    this.GET = GET;
    this.POST = POST;
    this.PUT = PUT;
    this.PATCH = PATCH;
    this.DELETE = DELETE;
  }

  request = async (type, params = {}) => {
    try {
      const response = await this[`_${type.toLowerCase()}`](params);

      if (
        response &&
        typeof response === 'object' &&
        response.hasOwnProperty('data')
      ) {
        return {
          ...response.data,
          success: 'success',
        };
      }

      return {
        success: false,
        message:
          'Ha sucedido un error inesperado, contacte con el administrador',
      };
    } catch (error) {
      const message = this.handleApiError(error);

      return {
        success: false,
        ...(error.response ? error.response.data : {}),
        message,
      };
    }
  };
  async _get({ url, useCache = false }) {
    return await this.API.get(url, { useCache });
  }

  async _post(params) {
    return await this.API({
      method: this.POST,
      ...params,
    });
  }

  async _put(params) {
    return await this.API({
      method: this.PUT,
      ...params,
    });
  }
  async _patch(params) {
    return await this.API({
      method: this.PATCH,
      ...params,
    });
  }

  async _delete(params) {
    return await this.API({
      url: params.url,
      method: this.DELETE,
      params: {
        ...params.data,
      },
    });
  }
}
