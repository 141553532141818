import React, { useState } from 'react'
import { FileService } from 'services';
import { Alert } from 'reactstrap';

export const FileUpload = ({ url = '', title = 'Subir archivo', onFinishUpload }) => {

    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);

    const [message, setMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    const selectFile = (event) => {
        setSelectedFiles(event.target.files || event.dataTransfer.files);
    };

    const upload = () => {
        const fileService = new FileService();

        let currentFile = selectedFiles[0];

        setProgress(0);
        setCurrentFile(currentFile);

        fileService.upload(currentFile, url, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        })
            .then((response) => {
                setMessage('Se ha subido correctamente el archivo');
                setProgress(0);
                setAlertType('success');
                setCurrentFile(undefined);
                onFinishUpload(response);
            })
            .catch((error) => {
                console.error()
                setProgress(0);
                setMessage('Ha sucedido un error en el proceso');
                setAlertType('danger');
                setCurrentFile(undefined);
            });

        setSelectedFiles(undefined);

    }

    return (
        <div>
            {currentFile && (
                <div className="progress">
                    <div
                        className="progress-bar progress-bar-info progress-bar-striped"
                        role="progressbar"
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: progress + "%" }}
                    >
                        {progress}%
              </div>
                </div>
            )}

            <label className="btn btn-default">
                <input
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    type="file" name="existencias"
                    onChange={selectFile}
                />
            </label>

            <button
                className="btn btn-success"
                disabled={!selectedFiles}
                onClick={upload}
            >
                {title}
            </button>

            {message && <Alert color={alertType}>
                {message}

            </Alert>}


        </div>
    );

}
