import React, { Component } from 'react'
import { Row, Col, Breadcrumb, BreadcrumbItem, FormGroup } from 'reactstrap';
import Page from 'components/Page';
import SalesTicketContainer from './SalesTicketContainer';
import { AdminService } from 'services';

class TuTrebolAdmin extends Component {
    state = {
        warehouses: []
    }

    async componentDidMount() {
        const adminService = new AdminService();
        const response = await adminService.getClientWarehouses(this.props.context['session']['payload']['id_client']);

        if (response.success) {
            this.setState({ warehouses: response.warehouses });
        } else {
            this.setState({ warehouses: [] })

        }

    }

    render() {
        return (
            <Page
                className="CustomAdminTuTrebol"
                title="Panel personalizado TuTrebol"
                breadcrumbs={[{ name: 'Panel TuTrebol ', active: true }]}>

                <Breadcrumb>
                    <BreadcrumbItem active><h4>Tickets venta</h4></BreadcrumbItem>
                </Breadcrumb>
                <Row>
                    <Col xs={12}>
                        <SalesTicketContainer context={this.props.context} warehouses={this.state.warehouses} />
                    </Col>

                </Row>
            </Page>
        )
    }
}


export default TuTrebolAdmin;