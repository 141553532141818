import React, { Component, Fragment } from 'react'
import classnames from 'classnames';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { Nav, NavItem, NavLink, TabContent, TabPane, Alert, Button } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import { MdInsertDriveFile } from 'react-icons/md';

class StockTable extends Component {
    state = {
        warehouses: [],
        loading: false,
        columns: [{
            Header: 'Cod.Almacen',
            accessor: 'cod_almacen',
            sortable: false,
        },
        {
            Header: 'Articulo',
            accessor: 'cod_articulo',
            sortable: false
        },
        {
            Header: 'Descripcion',
            accesor: 'desc_articulo',
            filterable: false,
            sortable: false,
            Cell: row => <span>{row.original.desc_articulo.toString()}</span>
        },
        {
            Header: 'Ubicacion',
            accesor: 'ubicacion',
            filterable: false,
            Cell: row => <span>{row.original.ubicacion.toString()}</span>
        },
        {
            Header: 'Existencias',
            accesor: 'existencias',
            Cell: row => <span>{row.original.existencias.toString()}</span>
        },
        {
            Header: 'Fecha Cad.',
            accesor: 'fecha_caducidad',
            sortable: true,
            filterable: false,
            Cell: row => <span>{row.original.fecha_caducidad}</span>

        }
        ],
        activeTab: '',
        alertMessage: '',
        alertType: ''
    }


    componentDidUpdate(prevProps) {
        if (prevProps.warehouses.length !== this.props.warehouses.length && this.props.warehouses.length > 0)
            this.changeActiveTab({
                target: {
                    id: this.props.warehouses[0]['name']
                }
            })
    }

    changeActiveTab = event => this.setState({ activeTab: event.target.id });

    render() {
        return (
            <Fragment>
                {this.props.alertMessage && (
                    <Alert color={this.props.alertType}>
                        {this.props.alertMessage}
                    </Alert>
                )}

                <ClipLoader loading={this.props.loading} />
                <Nav tabs className="custom-tabs">
                    {this.props.warehouses.map(warehouse => (
                        <NavItem key={warehouse.name} className="cursor-pointer">
                            <NavLink
                                id={warehouse.name}
                                className={classnames({
                                    active: this.state.activeTab === warehouse.name,
                                })}
                                onClick={this.changeActiveTab}
                            >
                                {warehouse.name.toUpperCase()}
                            </NavLink>
                        </NavItem>

                    ))}

                </Nav>

                <TabContent activeTab={this.state.activeTab} className="py-2 px-2">
                    {this.props.warehouses.map(warehouse => (
                        <TabPane key={warehouse.name + '_'} tabId={warehouse.name}>
                            <Button
                                className="my-2 mx-1"
                                as="a"
                                href={`${process.env.REACT_APP_API_URL}/txt/download/${this.props.client.id}/existencias.xlsx?token=${this.props.token}`}
                                rel="noopener noreferrer"
                                target="__blank"
                                color="primary"
                            >
                                <MdInsertDriveFile size={25} className="mr-2" />
                                Descargar fichero existencias
                            </Button>
                            <ReactTable
                                filterable
                                className="-striped -highlight"
                                data={warehouse.stock}
                                columns={this.state.columns}
                                defaultPageSize={30}
                                pageSizeOptions={[30, 50, 100, 150, 200]}
                                loading={this.state.loading}
                                previousText={'Anterior'}
                                nextText={'Siguiente'}
                                loadingText="Cargando stock..."
                                noDataText="No se han encontrado mas productos"
                                pageText="Página"
                                ofText="de"
                                rowsText="Filas"
                                pageJumpText="ir a la página"
                                rowsSelectorText="filas por página"
                            />
                        </TabPane>
                    ))}


                </TabContent>


            </Fragment>
        )
    }
}


export default StockTable;