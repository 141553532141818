import ApiRepository from './ApiRepository';

export default class FileService extends ApiRepository {
  constructor() {
    super();
  }

  async getOrderMedia(order, disk = 'management') {
    return this.request(this.GET, {
      url: `media/uploaded/${order.id}/${disk}`,
    });
  }

  async upload(params, url, onUploadProgress) {
    let formData = new FormData();

    Object.keys(params).forEach(key => {
      formData.append(key, params[key]);
    });

    if(params instanceof File) {
        formData.append('file', params);
    }

    return this.request(this.POST, {
      url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  }

  async uploadCustom(params, url, onUploadProgress) {
    let formData = new FormData();

    Object.keys(params).forEach(key => {
      formData.append(key, params[key]);
    });

    if(params instanceof File) {
        formData.append('file', params);
    }

    return this.request(this.POST, {
      url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  }

  async deleteMedia(filename, disk = 'management') {
    return this.request(this.DELETE, {
      url: `media/${disk}/delete?filename=${filename}`,
    });
  }
}
