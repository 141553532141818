import React, { Component } from 'react'
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Page from 'components/Page';
import { BoxStock, BoxStats } from '../../BoxStock'

class MosfashionAdmin extends Component {
    render() {
        return (
            <Page
                className="CustomAdminMosfashion"
                title="Panel personalizado Mosfashion"
                breadcrumbs={[{ name: 'Panel Mosfashion ', active: true }]}>

                <Breadcrumb>
                    <BreadcrumbItem active><h4>Gestion de stock</h4></BreadcrumbItem>
                </Breadcrumb>
                <Row>
                    <Col xs={12} sm={10} md={6} lg={6} xl={5}>
                        <BoxStock context={this.props.context} />
                    </Col>
                    <Col xs={12} sm={10} md={6} lg={6} xl={7}>
                        <BoxStats client={this.props.context.client} />
                    </Col>
                </Row>
            </Page>
        )
    }
}


export default MosfashionAdmin;