import ApiRepository from './ApiRepository';

export default class AdminService extends ApiRepository {
  constructor() {
    super();
  }

  async getManifiestos(from_date) {
    return await this.request(this.GET, {
      url: `/manifiestos/${from_date}`,
    });
  }

  async getCustomManifiesto(date, filename, type, token) {
    return await this.request(this.GET, {
      url: `/external/manifiesto/${date}/${filename}/${type}/${token}`,
    });
  }

  async boxStockStats($client) {
    return await this.request(this.GET, {
      url: `/box-stock/stats/${$client}`,
    });
  }

  async getClientWarehouses(client) {
    return await this.request(this.GET, {
      url: `/admin/warehouses/${client}`,
    });
  }

  async generateManifiesto(params) {
    return await this.request(this.POST, {
      url: '/manifiesto/generate',
      data: {
        ...params,
      },
    });
  }

  async generateCustomManifiesto(params) {
    return await this.request(this.POST, {
      url: '/manifiesto/generate/custom',
      data: {
        ...params,
      },
    });
  }

  async generateTariffHeading(params) {
    const data = new FormData();

    Object.keys(params).forEach(key => {
      if (key === 'files[]') {
        Array.from(params[key]).forEach(file => {
          data.append(key, file);
        });
      } else {
        data.append(key, params[key]);
      }
    });

    return await this.request(this.POST, {
      url: '/manifiesto/generate/tariff',
      data,
    });
  }

  async sendClientManifiestoViaEmail(client, from_date) {
    return await this.request(this.GET, {
      url: `/${client}/manifiesto/${from_date}`,
    });
  }

  async sendClientCustomManifiestoViaEmail(params) {
    return await this.request(this.POST, {
      url: `/${params.client}/manifiesto/custom`,
      data: {
        ...params,
      },
    });
  }

  async clientWarehouseStock(client_id) {
    return await this.request(this.GET, {
      url: `/txt/stock/${client_id}`,
    });
  }

  async pendingAndClosedOrders(client_id, from_date, until_date) {
    return await this.request(this.GET, {
      url: `/txt/orders/${client_id}?from_date=${from_date}&until_date=${until_date}`,
    });
  }

  async downloadFinishedOrders(client_id, params) {
    return await this.request(this.POST, {
      url: `/txt/finished-orders/${client_id}`,
      data: {
        ...params,
      },
    });
  }

  async downloadOrdersProductList(client_id, params) {
    return await this.request(this.POST, {
      url: `/txt/orders-product-list/${client_id}`,
      data: {
        ...params,
      },
    });
  }

  async fetchClients() {
    return await this.request(this.GET, {
      url: '/admin/clients',
      useCache: true,
    });
  }

  async fetchClientsWithWarehouses() {
    return await this.request(this.GET, {
      url: '/client/with-warehouses',
      useCache: true,
    });
  }

  async fetchRoles() {
    return await this.request(this.GET, {
      url: '/roles',
      useCache: true,
    });
  }

  async fetchSituations() {
    return await this.request(this.GET, {
      url: '/admin/situations',
      useCache: true,
    });
  }

  async fetchOrderStates() {
    return await this.request(this.GET, {
      url: '/admin/history-states',
      useCache: true,
    });
  }

  async queryOrders(query) {
    return await this.request(this.GET, {
      url: `/admin/query-orders?query=${query}`,
    });
  }

  async queryPackages(query) {
    return await this.request(this.GET, {
      url: `/admin/query-packages?query=${query}`,
    });
  }

  async fetchClientOrders(params) {
    return await this.request(this.POST, {
      url: '/admin/orders',
      data: {
        ...params,
      },
    });
  }

  async stepEditorClientSteps(id_warehouse, order_type) {
    return await this.request(this.GET, {
      url: `/admin/step-editor/client-steps?id_warehouse=${
        id_warehouse || ''
      }&order_type=${order_type || ''}`,
    });
  }

  async stepEditorCreateStep(params) {
    return await this.request(this.POST, {
      url: '/admin/step-editor/create-step',
      data: {
        ...params,
      },
    });
  }

  async stepEditorUpdateStep(params) {
    return await this.request(this.PATCH, {
      url: '/admin/step-editor/update-steps',
      data: {
        ...params,
      },
    });
  }

  async stepEditorUpdateOptions(params) {
    return await this.request(this.PATCH, {
      url: '/admin/step-editor/update-options',
      data: {
        ...params,
      },
    });
  }

  async stepEditorDeleteStep(step_name, id_warehouse, order_type) {
    return await this.request(this.DELETE, {
      url: `/admin/step-editor/delete-step/${step_name}/${id_warehouse || ''}/${
        order_type || ''
      }`,
    });
  }

  async resetOrders(params) {
    return await this.request(this.DELETE, {
      url: '/admin/reset-orders',
      data: {
        ...params,
      },
    });
  }

  async getCustomConfiguration(client, data = {}) {
    return await this.request(this.GET, {
      url: `/custom/configuration/${client}`,
      params: {
        ...data,
      },
    });
  }

  async updateCustomConfiguration(client, params = {}) {
    return await this.request(this.PUT, {
      url: `/custom/configuration/${client}`,
      data: {
        ...params,
      },
    });
  }
}
