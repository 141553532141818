import ApiRepository from './ApiRepository';

export default class CellService extends ApiRepository {
  constructor() {
    super();
  }

  async ordersToPrepare() {
    return await this.request(this.GET, {
      url: `/supermarket/orders`,
    });
  }

  async pendingOrders() {
    return await this.request(this.GET, {
      url: `/supermarket/pending-orders`,
    });
  }

  async preparedOrders() {
    return await this.request(this.GET, {
      url: `/supermarket/prepared-orders`,
    });
  }

  async assignedOrders() {
    return await this.request(this.GET, {
      url: `/supermarket/assigned-orders`,
    });
  }

  async getCategories() {
    return await this.request(this.GET, {
      url: `/supermarket/categories`,
    });
  }

  async productFromPrestashop(product_reference) {
    return await this.request(this.GET, {
      url: `/supermarket/product-from-prestashop?product_reference=${product_reference}`,
    });
  }

  async salesTicketOnDate(date, client) {
    return await this.request(this.GET, {
      url: `/supermarket/sales-ticket/${date}/${client}`,
    });
  }

  async salesTicketFromOrder(id_order) {
    return await this.request(this.GET, {
      url: `/supermarket/sales-ticket/${id_order}`,
    });
  }

  async assignPendingOrders(params) {
    return await this.request(this.POST, {
      url: `/supermarket/assign/orders`,
      data: {
        ...params,
      },
    });
  }

  async addProduct(orderId, params) {
    return await this.request(this.POST, {
      url: `/supermarket/product/${orderId}`,
      data: {
        ...params,
      },
    });
  }

  async replaceProduct(orderId, productId, params) {
    return await this.request(this.PUT, {
      url: `/supermarket/product-replace/${orderId}/${productId}`,
      data: {
        ...params,
      },
    });
  }


  async updateOrderBoxes(order_id, params) {
    return await this.request(this.PATCH, {
      url: `/supermarket/boxes/${order_id}`,
      data: {
        ...params,
      },
    });
  }
}
