import ApiRepository from './ApiRepository';

export default class PackageService extends ApiRepository {
  constructor() {
    super();
  }

  async searchPackageThatContainsOrder(order_reference) {
    return await this.request(this.GET, {
      url: `/package/${order_reference}`,
    });
  }

  async ordersFromPackage(package_id) {
    return await this.request(this.GET, {
      url: `/package/${package_id}/orders`,
    });
  }

  async fetchTodayPackages(params) {
    return await this.request(this.POST, {
      url: `/packages`,
      data: {
        ...params,
      },
    });
  }

  async fetchTodayPackagesLight(params, url = '/packages-light') {
    return await this.request(this.POST, {
      url,
      data: {
        ...params,
      },
    });
  }

  async reassignOrders(params) {
    return await this.request(this.POST, {
      url: `/package/reassign-order/${params['actual_package_id']}`,
      data: {
        ...params,
      },
    });
  }

  async enableGlobal(target_package, query, params) {
    return await this.request(this.POST, {
      url: `/packages/enable-global/${target_package}${query}`,
      data: {
        ...params,
      },
    });
  }

  async create(params) {
    return await this.request(this.POST, {
      url: '/package/create',
      data: {
        ...params,
      },
    });
  }

  async forceCreate(params) {
    return await this.request(this.POST, {
      url: `/package/force-create/${params['order_id']}`,
      data: {
        ...params,
      },
    });
  }

  async changeStatus(params) {
    return await this.request(this.PUT, {
      url: `/package/change-status/${params['package_id']}`,
      data: {
        ...params,
      },
    });
  }

  async changeStatuses(params) {
    return await this.request(this.PUT, {
      url: `/package/change-statuses`,
      data: {
        ...params,
      },
    });
  }

  async detachOrders(params) {
    return await this.request(this.POST, {
      url: `/package/detach/${params['package_list_id']}`,
      data: {
        ...params,
      },
    });
  }

  async update(params) {
    return await this.request(this.PATCH, {
      url: `/package/update/${params['package_id']}`,
      data: {
        ...params,
      },
    });
  }

  async updatePackagesDate(params) {
    return await this.request(this.PATCH, {
      url: `/packages/change-date`,
      data: {
        ...params,
      },
    });
  }

  async delete(package_list_id) {
    return await this.request(this.DELETE, {
      url: `/package/delete/${package_list_id}`,
    });
  }
}
