import React from 'react';
import { Content, Footer } from 'components/Layout';

const EmptyLayout = ({ children, displayFooter }) => (
  <main className="cr-app bg-light">
    <Content fluid>{children}</Content>
    {displayFooter && <Footer />}
  </main>
);

EmptyLayout.defaultProps = {
  displayFooter: true,
};

export default EmptyLayout;
