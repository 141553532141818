import React, { Component, Fragment } from 'react'
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Page from 'components/Page';
import ClientSelector from './ClientSelector';
import { FileUpload } from 'components/Common/FileUpload';
import StockTable from './StockTable';
import { AdminService } from 'services';
import { TokenUtils } from 'utils';
import OrdersTable from './OrdersTable';
import ConfigurationManagement from 'pages/AdminDashboard/ConfigurationManagement/ConfigurationManagement';
import { BLACKLIST_PRODUCTS } from 'components/Common/Constants';

class TXTAdmin extends Component {
    state = {
        selected_client: null,
        warehouses: [],
        alertMessage: '',
        alertType: '',
        table_loading: false,
        token: ''
    }

    componentDidMount() {
        this.setState({ token: TokenUtils.getTokenFromSession()['jwt'] })
    }

    updateSelectedClient = (selected_client) => {
        this.setState({ selected_client });
    }

    fetchStockData = async (selected_client = {}) => {
        try {
            this.setState({
                table_loading: true,
                warehouses: [],
                alertMessage: '',
                alertType: ''
            })
            const adminService = new AdminService();

            const response = await adminService.clientWarehouseStock(selected_client.id || this.state.selected_client.id);

            if (response.success && response.warehouses.length > 0) {
                this.setState({
                    table_loading: false,
                    warehouses: response.warehouses,
                    alertMessage: '',
                    alertType: ''
                })


            } else {
                this.setState({
                    table_loading: false,
                    warehouses: [],
                    alertMessage: response.message,
                    alertType: 'danger'
                })
            }



        } catch (error) {
            this.setState({
                table_loading: false,
                warehouses: [],
                alertMessage: error,
                alertType: 'danger'
            })
        }

    }

    render() {
        return (
            <Page
                className="CustomAdminTXT"
                title="Panel personalizado TXT"
                breadcrumbs={[{ name: 'Panel TXT ', active: true }]}>


                <Breadcrumb>
                    <BreadcrumbItem active><h4>Stock por cliente & almacen</h4></BreadcrumbItem>
                </Breadcrumb>
                <Row>

                    <Col xs={12} md={4} lg={6} xl={4}>
                        <ClientSelector
                            fetchStockData={this.fetchStockData}
                            updateSelectedClient={this.updateSelectedClient} />
                    </Col>

                    {this.state.selected_client && (
                        <Fragment>
                            <Row>
                                <ConfigurationManagement client={this.state.selected_client} configurations={[BLACKLIST_PRODUCTS]} />
                            </Row>
                            <Col xs={12}>
                                <Breadcrumb>
                                    <BreadcrumbItem active><h5>Pedidos en preparación {this.state.selected_client.name}</h5></BreadcrumbItem>
                                </Breadcrumb>
                                <OrdersTable
                                    client={this.state.selected_client}
                                    token={this.state.token}
                                />
                            </Col>

                            <Col xs={12} md={8} lg={6} xl={8}>
                                <Breadcrumb>
                                    <BreadcrumbItem active><h5>Actualizar existencias</h5></BreadcrumbItem>
                                </Breadcrumb>
                                <FileUpload
                                    title="Subir fichero"
                                    url={`/txt/upload-file/${this.state.selected_client.id}`}
                                    onFinishUpload={() => this.fetchStockData()}
                                />
                            </Col>

                            <Col xs={12}>
                                <StockTable
                                    token={this.state.token}
                                    client={this.state.selected_client}
                                    warehouses={this.state.warehouses}
                                    loading={this.state.table_loading}
                                    alertMessage={this.state.alertMessage}
                                    alertType={this.state.alertType}
                                />
                            </Col>


                        </Fragment>
                    )}


                </Row>

            </Page>
        )
    }
}


export default TXTAdmin;