import React from 'react'
import PropTypes from 'prop-types';


const ClientLogo = ({ client, style, className }) => {
    const logoPath = `/img/clients/${client.alias}.jpg`;


    const setMaxHeightBasedOnClient = (client) => {
        let maxHeight = '60px';

        switch (client.alias.toLowerCase()) {
            case 'mosfashion':
            case 'shuzulab':
            case 'municotrading':
                maxHeight = '30px';
                break;
            case 'sabinastore':
            case 'milukadoors':
            case 'txt':
                maxHeight = '40px';
                break;
            case 'tutrebol':
            case 'visanta':
            case 'almacenbarato':
            case 'wetmonster':
                maxHeight = '50px';
                break;
            case 'perfumescanarias':
                maxHeight = '80px';
                break;
            default:
                maxHeight = '60px';
                break;
        }

        return maxHeight;
    }

    return (
        <img
            style={{ maxHeight: setMaxHeightBasedOnClient(client), ...style }}
            className={className}
            src={logoPath}
            alt={client.alias}
            onError={(e) => {
                const newSrc = logoPath.replace('.jpg', '.png');
                e.target.src = newSrc
                e.target.onerror = e.target.width !== 0 ? null : 'error';

            }}
        />
    )
}

ClientLogo.propTypes = {
    client: PropTypes.object.isRequired,
    style: PropTypes.object,
    className: PropTypes.string
}

ClientLogo.defaultProps = {
    client: { alias: '' },
    className: "img-fluid client-logo"
}

export default ClientLogo