import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import NotificationSystem from 'react-notification-system';
import { confirmAlert } from 'react-confirm-alert';
import { API, handleApiError } from 'configuration/axiosConfig';
import { AuthService, ClientService, OrderService } from 'services';
import {
  BluetoothManager,
  Common,
  ZPLPrint,
  constants,
  TokenUtils,
} from 'utils';

const defaultContextState = {
  orders: [],
  client: null,
  step_loading: false,
  selected_order: {},
  selected_product: {},
  session: null,
  weight: null,
  error: {
    alertType: '',
    alertMessage: '',
    type: '',
  },
  services: {
    printers: {
      zpl: '',
      zplPrinter: null,
      zplPrinterError: '',
    },
    bluetoothManager: null,
  },
  notificationSystem: null,
  globalAlertMessage: '',
  globalAlertType: '',
  productsValidated: [],
  idleTimer: null,
  sidebarContents: [],
};

export const GlobalContext = React.createContext(defaultContextState);

class GlobalContextProvider extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  state = defaultContextState;

  async componentDidMount() {
    const session = TokenUtils.getTokenFromSession() || null;
    let client = await this.fetchClient(session);

    this.setState({ session, client });
    this.initializeServices();
  }

  /**
   * COMMON FUNCTIONS THAT CAN BE USED EVERYWHERE
   */

  fetchClient = async (session = this.state.session) => {
    let client = null;

    if (session) {
      const clientCached = await Common.getCachedItem('client', session);

      if (clientCached && clientCached.id === session.payload.id_client) {
        return clientCached;
      }

      const clientService = new ClientService();
      const response = await clientService.getData(session.payload.id_client);

      if (
        response.success &&
        response.client &&
        Object.keys(response.client).length > 0
      ) {
        const logistic_operators = await this.getClientLogisticOperators();

        client = {
          ...response.client,
          logistic_operators,
          related_configurations: response.related_configurations,
        };

        delete client['shop_url'];

        sessionStorage.setItem('client', JSON.stringify(client));
      }
    }

    return client;
  };

  setSession = async session => {
    let client = await this.fetchClient(session);

    this.setState({ session, client });
  };

  setActualProductValidation = productsValidated =>
    this.setState({ productsValidated });

  createGlobalAlert = (alertMessage, alertType, type = '') =>
    this.setState({ error: { alertMessage, alertType, type } });

  handleNetworkError = response => {
    this.setState({
      step_loading: false,
      error: {
        alertType: 'danger',
        alertMessage: response.message,
        type: response.type || '',
      },
    });
  };

  cleanGlobalError = () =>
    this.setState({
      services: {
        ...this.state.services,
        printers: {
          ...this.state.services.printers,
          zplPrinterError: '',
        },
      },
      error: { alertType: '', alertMessage: '', type: '' },
    });

  getClientLogisticOperators = async (service = null) => {
    const logisticOperatorsCached = await Common.getCachedItem(
      'logistic_operators',
      this.state.session,
    );

    if (logisticOperatorsCached) return logisticOperatorsCached;

    const clientService = service || new ClientService();
    const response = await clientService.logisticOperators();

    if (response.success) {
      sessionStorage.setItem(
        'logistic_operators',
        JSON.stringify(response.logistic_operators),
      );

      return response.logistic_operators;
    }

    return [];
  };

  setClient = client => this.setState({ client });

  /**
   *  ENDCOMMON FUNCTIONS THAT CAN BE USED EVERYWHERE
   */

  /**
   * FUNCTIONS THAT HANDLE ORDERS
   */
  getOrder = async order_reference => {
    const orderService = new OrderService();
    const response = await orderService.getByReference(order_reference);

    if (response.success) {
      this.setState({
        selected_order: response.order,
        selected_product: {},
        error: {
          alertType: '',
          alertMessage: '',
          type: '',
        },
      });
    } else {
      this.handleNetworkError(response);
    }
  };

  setOrder = order =>
    this.setState({
      selected_order: order,
      error: {
        alertType: '',
        alertMessage: '',
        type: '',
      },
    });

  setSelectedProduct = selected_product =>
    this.setState({
      selected_product,
      error: {
        alertType: '',
        alertMessage: '',
        type: '',
      },
    });

  updateOrderSteps = async ({
    order_reference = this.state.selected_order.id,
    action = 'initialize',
    step_id = null,
    signal = null,
    tag_validation_value = '',
  }) => {
    this.setState({
      step_loading: step_id,
      error: { alertType: '', alertMessage: '', type: '' },
    });

    const orderService = new OrderService();
    const response = await orderService.updateSteps({
      order_reference,
      action,
      step_id,
      signal,
      tag_validation_value,
    });

    const { success, order, ...data } = response;

    if (success) {
      this.setState({
        ...this.state,
        step_loading: false,
        selected_order: order,
        selected_product: {},
        ...data,
      });
    } else {
      this.handleNetworkError(response);
    }

    return response;
  };

  printPackageListInvoice = async (event, package_id) => {
    try {
      const invoice_response = await API({
        url: `/packages/${package_id}/download`,
        method: 'GET',
        responseType: 'blob',
        xsrfCookieName: 'XSRF-TOKEN',
        xsrfHeaderName: 'X-XSRF-TOKEN',
      });

      if (invoice_response.data) {
        const file = invoice_response.data;
        const type = 'application/pdf';
        const url = window.URL.createObjectURL(new Blob([file], { type }));
        const iframe = document.createElement('iframe');

        iframe.setAttribute('src', url);
        iframe.setAttribute('style', 'visibility:hidden;');

        document.body.appendChild(iframe);

        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        iframe.contentWindow.close();

        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error(error);
      let message = handleApiError(error);
      this.createGlobalAlert(message, 'danger');
    }
  };

  /**
   * END FUNCTIONS THAT HANDLE ORDERS
   */

  /*
     FUNCTIONS THAT HANDLE PRINTER INTERACTION
   */

  initializeServices = () => {
    try {
      this.setState({
        services: {
          bluetoothManager: new BluetoothManager({
            tabletDebug: process.env.REACT_APP_DEBUG,
          }),
          printers: { zplPrinter: new ZPLPrint() },
        },
      });
    } catch (error) {
      console.error(error);
      this.setState({
        services: {
          bluetoothManager: null,
          printers: {
            zpl: '',
            zplPrinter: null,
            zplPrinterError: error,
          },
        },
      });
    }
  };

  syncDevices = () => {
    if (!this.state.services.printers.zplPrinter) {
      return this.setState({
        services: {
          ...this.state.services,
          printers: {
            ...this.state.services.printers,
            zplPrinterError:
              'Ha habido un error inicializando el driver de la impresora ZPL',
          },
        },
      });
    }

    const syncResult = this.state.services.printers.zplPrinter.syncDevices();

    return this.setState({
      services: {
        ...this.state.services,
        printers: {
          ...this.state.services.printers,
          zplPrinterError: typeof syncResult === 'string' ? syncResult : '',
        },
      },
    });
  };

  printZPL = (zpl = null) => {
    if (!this.state.services.printers.zplPrinter) {
      return this.setState({
        services: {
          ...this.state.services,
          printers: {
            ...this.state.services.printers,
            zplPrinterError:
              'El driver de la impresora no está inicializado, no se puede imprimir',
          },
        },
      });
    }

    if (!zpl && this.state['selected_order']['ship_labels']) {
      zpl = this.state['selected_order']['ship_labels'][0]['label'];
    }

    const writeError = this.state.services.printers.zplPrinter.writeToSelectedPrinter(
      zpl,
    );

    this.setState({
      services: {
        ...this.state.services,
        printers: {
          ...this.state.services.printers,
          zpl,
          zplPrinterError: writeError || '',
        },
      },
    });

    return writeError;
  };

  printOrderLabels = (orders = []) => {
    const zpls = orders
      .map(order => {
        if (order.ship_labels) {
          const zpl = order.ship_labels
            .filter(
              shipLabel =>
                shipLabel.format === 'ZPL' && Boolean(shipLabel.label),
            )
            .map(shipLabel => shipLabel.label)
            .join('')
            .trim();

          if (!zpl) {
            alert(
              `Los bultos del pedido # ${order.id_order_prestashop} no tienen un contenido correcto o están corruptos para la impresión de etiqueta`,
            );
          }

          return zpl;
        } else {
          alert(
            `El pedido # ${order.id_order_prestashop} no tiene bultos asignados aun`,
          );
        }
      })
      .join('')
      .trim();

    this.printZPL(zpls);
  };

  onIdle = () => {
    const { idleTimer } = this.state;

    if (idleTimer) {
      let lastActiveTime = new Date(idleTimer.getLastActiveTime());

      confirmAlert({
        title: 'Atención',
        message: `Has estado inactivo ${lastActiveTime.getMinutes()} minutos, le recomendamos cerrar la sesión actual ¿desea hacerlo?`,
        buttons: [
          {
            label: 'Si',
            onClick: async () => await this.authLogout(this.props.history),
          },
          {
            label: 'No',
            onClick: () => idleTimer.reset(),
          },
        ],
      });
    }
  };

  /*
      END FUNCTIONS THAT HANDLE PRINTER INTERACTION
    */

  /*
      FUNCTIONS THAT HANDLE AUTHENTICATION 
   */

  authLogout = async history => {
    const authService = new AuthService();
    const response = await authService.logout();

    if (response.success) {
      TokenUtils.removeTokenFromSession();

      this.setState({ session: null });

      if (!history && typeof window !== 'undefined') {
        window.location.replace(`${window.location.origin}/login`);
      } else {
        history.push('/login');
      }
    } else {
      this.handleNetworkError(response);
    }
  };

  /*
    END FUNCTIONS THAT HANDLE AUTHENTICATION 
  */

  render() {
    return (
      <GlobalContext.Provider
        value={{
          ...this.state,
          createGlobalAlert: this.createGlobalAlert,
          cleanGlobalError: this.cleanGlobalError,
          getClientLogisticOperators: this.getClientLogisticOperators,
          setClient: this.setClient,
          getOrder: this.getOrder,
          setOrder: this.setOrder,
          setSession: this.setSession,
          setSelectedProduct: this.setSelectedProduct,
          updateOrderSteps: this.updateOrderSteps,
          printPackageListInvoice: this.printPackageListInvoice,
          initializeServices: this.initializeServices,
          printOrderLabels: this.printOrderLabels,
          syncDevices: this.syncDevices,
          printZPL: this.printZPL,
          authLogout: this.authLogout,
          setActualProductValidation: this.setActualProductValidation,
        }}
      >
        {this.props.children}

        <NotificationSystem
          dismissible={false}
          ref={notificationSystem => {
            if (!this.state.notificationSystem) {
              this.setState({ notificationSystem });
            }
          }}
          newOnTop
          style={constants.NOTIFICATION_SYSTEM_STYLE}
        />

        <IdleTimer
          ref={idleTimer => {
            if (!this.state.idleTimer) {
              this.setState({ idleTimer });
            }
          }}
          element={document}
          onIdle={this.onIdle}
          debounce={250}
          timeout={1000 * 60 * 15}
        />
      </GlobalContext.Provider>
    );
  }
}

export default GlobalContextProvider;
