/**
 * El formato fecha de javascript por defecto no renderiza
 * en un input html --> '4/11/2019'
 * Hay que convertirlo a este formato --> '2019-11-04'
 *
 * TL;DR Use YYYY-MM-DD date format or it won't display
 * @param Date date
 * @param String locale
 *
 * @return String dateFormatted
 */

const formatDateForHtmlInput = (date = null, locale = 'es-ES', days = null) => {
  let dateFormatted = date || new Date();

  if (days) {
    dateFormatted.setDate(dateFormatted.getDate() - days);
  }

  let year = dateFormatted.getFullYear();
  let day = (dateFormatted.getDate() < 10 ? '0' : '') + dateFormatted.getDate();
  let month = dateFormatted.getMonth();

  month = month < 9 ? '0' + (month + 1) : (month + 1).toString();

  return `${year}-${month}-${day}`;
};

const formatSpanishDate = (date = new Date()) => {
  const monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return day + ' ' + monthNames[monthIndex] + ' ' + year;
};

const formatDate = date => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export default { formatDateForHtmlInput, formatDate, formatSpanishDate };
