import EscPosEncoder from 'esc-pos-encoder';
import { Common } from 'utils';

class EscPosCommands {
  constructor(encoder = null) {
    this.encoder = encoder || new EscPosEncoder();
  }

  createBarcodeLabel = (
    title,
    code,
    codeType = 'code128',
    height = 200,
    size = [0x1d, 0x21],
  ) => {
    const result = this.encoder
      .initialize()
      .align('center')
      .bold()
      .raw(size)
      .text(Common.utf8Encode(title.toString()))
      .newline()
      .barcode(code.toString(), codeType, height)
      .line(code.toString())
      .newline()
      .newline()
      .cut('full')
      .encode();

    return result;
  };

  createSupermarketLabel = (order, params) => {
    const orderData = JSON.parse(order.data) || null;

    const order_reference = order.id_order_prestashop.toString();
    const spaces = ' '.repeat(6);
    const logo = new Image();

    logo.src = `${window.location.origin}/img/clients/${order.client.alias}.jpg`;

    const result = this.encoder
      .initialize()
      .align('center')
      .size('normal')
      .image(logo, 144, 144, 'atkinson')
      .newline()
      .line(Common.removeAccents(orderData.hypermarket.hypermarket.name))
      .line('CentrohiperTenerife, S.L.')
      .line('B38700753')
      .line('Tfno. 671690714')
      .line(order_reference)
      .line(`Bulto ${params.box}/${params.boxes.length}`)
      .line('------------------------------------------------')
      .align('left')
      .line(spaces + Common.removeAccents(order.customer.fullname))
      .line(spaces + order.customer.address1)
      .line(spaces + order.customer.address2)
      .line(
        spaces + order.customer.phone || spaces + order.customer.phone_mobile,
      )
      .line(spaces + order.customer.city)
      .text(spaces + order.other || '')
      .align('center')
      .line('------------------------------------------------')
      .barcode(order_reference, 'code128', 100)
      .line(order_reference)
      .align('left')
      .line(`${spaces}Observaciones: ${order.other}`)
      .newline()
      .newline()
      .newline()
      .cut('full')
      .encode();

    return result;
  };

  createProductsTicketLabel = order => {
    const orderData = JSON.parse(order.data) || null;
    const order_reference = order.id_order_prestashop.toString();

    const logo = new Image();
    logo.src = `${window.location.origin}/img/clients/${order.client.alias}.jpg`;

    const maxProductLength = 24;
    const ticketProductsHeader = `COD. PRODUCTO${' '.repeat(
      maxProductLength - 7,
    )}CANT. PVP  IMPORTE`;

    const ticket = order.ticket;
    const currency = 'EUR';
    const totalProducts = Object.values(ticket.products).reduce(
      (acc, current) => {
        return acc + current.quantity;
      },
      0,
    );

    let result = this.encoder
      .initialize()
      .align('center')
      .size('normal')
      .image(logo, 144, 144, 'atkinson')
      .newline()
      .line(
        Common.removeAccents(
          `${orderData.hypermarket.hypermarket.name} - ${orderData.hypermarket.hypermarket.island}`,
        ),
      )
      .line(
        //@TODO - OBTENER ESTOS DATOS DE LA EMPRESA DE FORMA DINÁMICA
        orderData.hypermarket.hypermarket.company_name ||
          'CentrohiperTenerife, S.L.',
      )
      .line(orderData.hypermarket.hypermarket.cif || 'B38700753')
      .line(`Tfno.  ${orderData.hypermarket.hypermarket.phone || '671690714'}`)
      .line(Common.removeAccents(orderData.hypermarket.hypermarket.email))
      .line(
        Common.removeAccents(
          orderData.hypermarket.hypermarket.location.address,
        ),
      )
      .newline()
      .align('left')
      .line(`Pedido: # ${order_reference}`)
      .line('Fecha: ' + new Date().toLocaleString())
      .line(`Bultos: ${orderData.boxes.length}`)
      .line(`Total articulos: ${totalProducts}`)
      .line(`Tipo de entrega: # ${order.carrier}`)
      .line(`Nombre: ${order.customer.fullname}`)
      .line('------------------------------------------------')
      .line(ticketProductsHeader)
      .line('------------------------------------------------');

    let productsAdded = [];
    let productsReplaced = [];

    Object.keys(ticket.products).forEach(product_id => {
      const product = ticket.products[product_id];

      if (product) {
        if (product.was_replaced) {
          productsReplaced.push(product);
        }

        if (product.was_added) {
          productsAdded.push(product);
        }

        result = result.line(this._newProductLine(product, maxProductLength));
      }
    });

    const {
      added_total_price,
      replaced_total_price,
      total_price,
      total_price_partial_validations,
      total_price_original,
      total_price_difference,
      products_to_remove
    } = ticket;

    const prefixSpace = ' '.repeat(maxProductLength);

    const paymentMethod = order.payment_method.trim();

    result = result
      .newline()
      .line('------------------------------------------------')
      .bold()
      .line(
        `${prefixSpace}TOTAL: ${parseFloat(total_price).toFixed(
          2,
        )} ${currency}`,
      )
      .newline()
      .line(
        `${' '.repeat(
          maxProductLength - paymentMethod.length,
        )} ${paymentMethod} PEDIDO WEB: ${total_price_original} ${currency}`,
      )
      .line(`${prefixSpace}DIFERENCIA: ${total_price_difference} ${currency}`);

    result = result
      .newline()
      .line('---------------INCIDENCIAS----------------------')
      .newline();

      products_to_remove.forEach((productRemoved, index) => {
        if (index === 0) {
          result = result
            .newline()
            .line('---------------NO SERVIDOS----------------------');
        }
  
        result = result.line(
          this._newProductLine(productRemoved, maxProductLength),
        );
      })

    productsAdded.forEach((productAdded, index) => {
      if (index === 0) {
        result = result
          .newline()
          .line('---------------ANADIDOS----------------------');
      }

      result = result.line(
        this._newProductLine(productAdded, maxProductLength),
      );
    });

    productsReplaced.forEach((productReplaced, index) => {
      if (index === 0) {
        result = result
          .newline()
          .line('---------------REEMPLAZOS----------------------');
      }

      result = result.line(
        this._newProductLine(
          productReplaced.diff_replaced.removed,
          maxProductLength,
        ),
      );

      result = result
        .line('REEMPLAZADO POR: ')
        .line(
          this._newProductLine(
            productReplaced.diff_replaced.new,
            maxProductLength,
          ),
        )
        .newline();
    });

    result = result
      .newline()
      .newline()
      .line(`${prefixSpace}ANADIDOS: ${added_total_price} ${currency}`)
      .line(
        `${prefixSpace}REEMPLAZOS: ${parseFloat(
          replaced_total_price.new - replaced_total_price.removed,
        ).toFixed(2)} ${currency}`,
      )
      .line(
        `${prefixSpace}PARCIALES: -${total_price_partial_validations} ${currency}`,
      )
      .newline()
      .newline()
      .cut('full')
      .encode();

    return result;
  };

  _newProductLine = (product, maxProductLength) => {
    let product_name = product.name.substr(0, maxProductLength);
    let quantity_to_show = product.quantity;

    if (product_name.length < maxProductLength) {
      product_name += ' '.repeat(maxProductLength - product_name.length);
    }

    let total_price = product.total_price_original;

    if (product.was_validated_partially) {
      quantity_to_show = `(${product.quantity_validated || 0}/${
        product.quantity
      })`;
      total_price = product.total_price_validated;
    }

    return `${product.ean13} ${product_name} ${quantity_to_show} ${product.unit_price} ${total_price}`;
  };
}

export default EscPosCommands;
