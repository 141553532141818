import ApiRepository from './ApiRepository';

export default class CellService extends ApiRepository {
  constructor() {
    super();
  }

  async getCellsClientData(client_id, useCache = false) {
    return await this.request(this.GET, {
      url: `/cells/${client_id}`,
      useCache,
    });
  }

  async scanProductReference(params) {
    return await this.request(this.POST, {
      url: '/cells/scanner',
      data: {
        ...params,
      },
    });
  }

  async assignProductToCell(params, product_id) {
    return await this.request(this.POST, {
      url: `/cells/assign/${product_id}`,
      data: {
        ...params,
      },
    });
  }

  async resetCell(cell_id, params = {}) {
    return await this.request(this.POST, {
      url: `/cells/reset/${cell_id}`,
      data: {
        ...params,
      },
    });
  }

  async removeProductFromCell(cell_id, product_id) {
    return await this.request(this.PATCH, {
      url: `/cells/remove/${cell_id}/${product_id}`,
      data: {},
    });
  }
}
