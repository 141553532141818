import React, { Component, Fragment } from 'react'
import ReactTable from 'react-table';
import treeTableHOC from 'react-table/lib/hoc/treeTable';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import { Alert, Button, Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import { MdInsertDriveFile } from 'react-icons/md';
import { AdminService } from 'services';
import { Common, DateUtils } from 'utils';

import "react-table/react-table.css";
class OrdersTable extends Component {
    state = {
        selectedAll: false,
        pending_orders: [],
        closed_orders: [],
        selected_orders: [],
        filtered_orders: [],
        activeFilters: [],
        today: new Date().toLocaleDateString('es-ES'),
        from_date: DateUtils.formatDateForHtmlInput(),
        until_date: DateUtils.formatDateForHtmlInput(),
        loading: false,
        columns: [
            {
                Header: 'Fecha preparación',
                accessor: 'last_step.updated_at',
                sortable: true,
                filterable: false
            },
            {
                Header: 'Identificador',
                accessor: 'id_order_prestashop',
                sortable: true,
            },
            {
                Header: 'Envío',
                accessor: 'logistic_operator',
                filterable: false,
                sortable: false,
                Cell: row => {
                    const alias = Common.lowerAndReplaceSpecialCharacters(row.value.alias);

                    return (
                        <img
                            alt={alias}
                            className={`img-fluid center ${alias}-logo-table`}
                            src={`/img/logistic_operators/${alias}.jpg`}
                        />
                    );
                }
            },
            {
                Header: 'Destino',
                accessor: 'customer',
                filterable: true,
                sortable: false,
                filterMethod: (filter, row) => {
                    const country = row.customer.country.toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "");

                    const filterValue = filter.value.toLowerCase();
                    return filter.value && country.includes(filterValue);
                },
                Cell: row => <p>{row.value.country}</p>
            },
            {
                Header: 'Estado',
                accessor: 'active_status_translated',
                sortable: false,
                Cell: row => {
                    const situationMapper = {
                        'EN ESPERA': 'info',
                        PENDIENTE: 'danger',
                        PREPARÁNDOSE: 'warning',
                        PREPARADO: 'success',
                        CERRADO: 'success',
                        ACTIVO: 'warning'
                    };

                    return (
                        <div
                            className={`table-${
                                situationMapper[row.value]
                                } font-weight-500`}
                        >
                            {row.value}
                        </div>
                    );
                }
            },
        ],
        alertMessage: '',
        alertType: ''
    }

    componentDidMount() {
        this.fetchPendingAndClosedOrders();
    }

    fetchPendingAndClosedOrders = async () => {
        try {
            this.setState({
                loading: true,
                alertMessage: '',
                alertType: '',
                selected_orders: [],
            });

            const adminService = new AdminService();
            const response = await adminService.pendingAndClosedOrders(
                this.props.client.id, this.state.from_date, this.state.until_date);

            if (response.success) {
                const { pending_orders, closed_orders } = response;
                this.setState({
                    pending_orders,
                    closed_orders,
                    filtered_orders: this.state.activeFilters.length === 0 ?
                        pending_orders.concat(closed_orders) :
                        this.filterOrders(),
                    loading: false
                })
            } else {
                this.setState({
                    filtered_orders: [],
                    loading: false,
                    alertMessage: response.message,
                    alertType: 'danger'
                });

            }

        } catch (error) {
            this.setState({
                activeFilters: [],
                loading: false,
                filtered_orders: [],
                selected_orders: [],
                orders: [],
                alertMessage: error,
                alertType: 'danger'
            })
        }
    }

    downloadFinishedOrders = async () => {

        const selectedOrders = this.state.selected_orders.map(order => order.replace('select-', ''))

        const adminService = new AdminService();
        const response = await adminService.downloadFinishedOrders(this.props.client.id, { orders: selectedOrders });

        try {
            if (response.success) {
                this.setState({
                    alertMessage: '',
                    alertType: '',
                    loading: false
                })

                window.open(response.url + `?token=${this.props.token}`, '_blank');
            } else {
                this.setState({
                    loading: false,
                    alertMessage: response.message,
                    alertType: 'danger'
                });
            }

        } catch (error) {
            this.setState({
                loading: false,
                alertMessage: error,
                alertType: 'danger'
            })
        }
    }

    downloadOrdersProductList = async () => {

        const selectedOrders = this.state.selected_orders.map(order => order.replace('select-', ''))

        const adminService = new AdminService();
        const response = await adminService.downloadOrdersProductList(this.props.client.id, { orders: selectedOrders });

        try {
            if (response.success) {
                this.setState({
                    alertMessage: '',
                    alertType: '',
                    loading: false
                })

                window.open(response.url + `?token=${this.props.token}`, '_blank');
            } else {
                this.setState({
                    loading: false,
                    alertMessage: response.message,
                    alertType: 'danger'
                });
            }

        } catch (error) {
            this.setState({
                loading: false,
                alertMessage: error,
                alertType: 'danger'
            })
        }
    }


    handleInputChange = (event) => {
        this.setState({
            filtered_orders: [],
            selected_orders: [],
            [event.target.name]: event.target.value,
        }, () => this.fetchPendingAndClosedOrders())


    }

    onToggleAll = () => {
        const selectedAll = !this.state.selectedAll;
        const selected_orders = [];

        if (selectedAll) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = this.ordersTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            // we just push all the IDs onto the selected_orders array
            currentRecords.forEach(item => {
                selected_orders.push(`select-${item._original['id_order_prestashop']}`);
            });
        }
        this.setState({ selectedAll, selected_orders });

    }

    isSelected = key => {
        return this.state.selected_orders.includes(`select-${key}`);
    }

    toggleSelection = (key, shift, row) => {
        let selected_orders = [...this.state.selected_orders];
        const keyIndex = selected_orders.indexOf(key);

        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selected_orders = [
                ...selected_orders.slice(0, keyIndex),
                ...selected_orders.slice(keyIndex + 1)
            ];
        } else {
            // it does not exist so add it
            selected_orders.push(key);
        }
        // update the state
        this.setState({ selected_orders });
    }

    handleCheckboxFilter = (event) => {
        let activeFilters = [...this.state.activeFilters];

        if (event.target.checked) {
            activeFilters = activeFilters.concat([event.target.value]);
        } else {
            activeFilters = activeFilters.filter(value => value !== event.target.value);
        }

        this.setState({
            activeFilters,
            filtered_orders: this.filterOrders(activeFilters)
        });
    }

    filterOrders = (activeFilters = this.state.activeFilters) => {
        let orders = [];


        if (activeFilters.includes('CLOSE') && activeFilters.includes('PENDING') || activeFilters.length === 0) {
            orders = this.state.pending_orders.concat(this.state.closed_orders);
        } else if (activeFilters.includes('CLOSE') && !activeFilters.includes('PENDING')) {
            orders = [...this.state.closed_orders];
        } else if (!activeFilters.includes('CLOSE') && activeFilters.includes('PENDING')) {
            orders = [...this.state.pending_orders];
        } else {
            orders = this.state.pending_orders.concat(this.state.closed_orders);
        }

        return orders;
    }



    render() {
        const SelectTable = selectTableHOC(treeTableHOC(ReactTable));

        return (
            <Fragment>
                {this.state.alertMessage && (
                    <Alert color={this.state.alertType}>
                        {this.state.alertMessage}
                    </Alert>
                )}
                <Row>
                    <Col xs={12} md={6}>
                        <Button
                            block
                            disabled={this.state.selected_orders.length === 0}
                            className="mt-2 mb-4 mx-1"
                            onClick={this.downloadFinishedOrders}
                            color="primary">
                            <MdInsertDriveFile size={25} className="mr-2" />
                    Descargar pedidos salida ({this.state.selected_orders.length} seleccionados)
                </Button>
                        <Button
                            block
                            disabled={this.state.selected_orders.length === 0}
                            className="my-2 mx-1"
                            onClick={this.downloadOrdersProductList}
                            color="warning">
                            <MdInsertDriveFile size={25} className="mr-2" />
                    Descargar lista de productos ({this.state.selected_orders.length} seleccionados)
                </Button>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form style={{ justifyContent: 'end' }}>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <FormGroup style={{ maxWidth: '210px' }} >
                                        <Label for="from_date">Fecha desde:</Label>
                                        <Input
                                            onChange={this.handleInputChange}
                                            id="from_date"
                                            type="date"
                                            name="from_date"
                                            value={this.state.from_date}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <FormGroup style={{ maxWidth: '210px' }} >
                                        <Label for="until_date">Fecha hasta:</Label>
                                        <Input
                                            onChange={this.handleInputChange}
                                            id="until_date"
                                            type="date"
                                            name="until_date"
                                            value={this.state.until_date}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup check inline>
                                <Label check>
                                    <Input
                                        onChange={this.handleCheckboxFilter}
                                        type="checkbox"
                                        name="order-filter"
                                        value='PENDING'
                                        checked={this.state.activeFilters.includes('PENDING')} />
                                        Pendientes
                                </Label>
                            </FormGroup>
                            <FormGroup check inline>
                                <Label check>
                                    <Input
                                        onChange={this.handleCheckboxFilter}
                                        type="checkbox"
                                        name="order-filter"
                                        value='CLOSE'
                                        checked={this.state.activeFilters.includes('CLOSE')} />
                                       Cerrados
                                </Label>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>


                <h5>Total: {this.state.filtered_orders.length}</h5>

                {this.state.loading ? <ClipLoader loading /> :
                    <SelectTable
                        ref={r => (this.ordersTable = r)}
                        filterable
                        keyField="id_order_prestashop"
                        selectType='checkbox'
                        selectAll={this.state.selectedAll}
                        toggleAll={this.onToggleAll}
                        toggleSelection={this.toggleSelection}
                        isSelected={this.isSelected}
                        className="-striped -highlight"
                        data={this.state.filtered_orders}
                        columns={this.state.columns}
                        defaultPageSize={20}
                        pageSizeOptions={[20, 50, 100, 150, 200]}
                        loading={this.state.loading}
                        previousText={'Anterior'}
                        nextText={'Siguiente'}
                        loadingText="Cargando ordenes en preparación..."
                        noDataText="No se han encontrado mas órdenes"
                        pageText="Página"
                        ofText="de"
                        rowsText="Filas"
                        pageJumpText="ir a la página"
                        rowsSelectorText="filas por página"
                    />
                }

            </Fragment>
        )
    }
}


export default OrdersTable;